import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  Input,
} from '@angular/core';
import { TodoStateService } from '../todo-state.service';
import { TodoEvents } from '../../state/todo/events';
import { TodoState } from '../../state/todo/state';
import { StreamInterface } from '@aion/core/management/stream-interface.js';
import { timedSignal } from '@aion/core/utils/timedSignal.js';

@Component({
  selector: 'app-todo-list-create',
  templateUrl: './todo-list-create.component.html',
  styleUrls: ['./todo-list-create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodoListCreateComponent {
  todoState = inject(TodoStateService);

  stream = input.required<StreamInterface<TodoState, TodoEvents>>();

  async create(value: HTMLInputElement) {
    await this.stream().push(
      'create-todo',
      {
        title: value.value,
      },
      { signal: timedSignal() },
    );
    value.value = '';
  }

  keyUp(evt: KeyboardEvent, value: HTMLInputElement) {
    if (evt.key === 'Enter') {
      this.create(value);
    }
  }
}
