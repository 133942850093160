import { JwtSessionStorage } from '@aion/client/auth/jwt-session-storage.js';
import { validate } from '@aion/core/typing/validate.js';
import { TypeDescription } from '@aion/core/typing/type-description.js';

export function createLocalSession<T extends TypeDescription>(
  key: string = 'auth',
  type: T,
): JwtSessionStorage<T['_output']> {
  return {
    async clear() {
      localStorage.removeItem(key);
    },
    async load() {
      const value = localStorage.getItem(key);
      if (value) {
        try {
          const data = JSON.parse(value);
          const [, parsed] = validate(data, type);
          if (parsed) {
            return parsed;
          }
        } catch {
          return null;
        }
      }
      return null;
    },
    async save(state) {
      localStorage.setItem(key, JSON.stringify(state));
    },
  };
}
