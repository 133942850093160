import { Lock } from './lock.js';
import { awaitIteration } from './await-iteration.js';

export function createMemoryLock(): Lock {
  const locks: Record<string, Promise<void>> = {};

  return {
    acquire<T>(key: string, fn: () => Promise<T>): Promise<T> {
      const existing = locks[key];
      if (existing) {
        const chain = existing.then(fn);
        locks[key] = chain.then(() => {}).catch();
        return chain;
      } else {
        const chain = fn();
        locks[key] = chain.then(() => {}).catch();
        return chain;
      }
    },
    acquireGenerator<T>(
      key: string,
      genFn: () => AsyncGenerator<T>,
    ): AsyncGenerator<T> {
      const existing = locks[key];
      if (existing) {
        let resolved: () => void;
        const chain = existing.then(
          () =>
            new Promise<void>((resolve) => {
              resolved = resolve;
            }),
        );
        locks[key] = chain.catch();
        return awaitIteration(existing, resolved!, genFn);
      } else {
        let resolved: () => void;
        const chain = new Promise<void>((resolve) => {
          resolved = resolve;
        });
        locks[key] = chain.catch();
        return awaitIteration(Promise.resolve(), resolved!, genFn);
      }
    },
  };
}
