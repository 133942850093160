import { createType } from './create-type.js';
import { RecordTypeDescription } from './record-type-description.js';
import { TypeDescription } from './type-description.js';
import { StringTypeDescription } from './string-type-description.js';

export const record = <T extends TypeDescription>(
  keyType: StringTypeDescription,
  valueType: T,
) =>
  createType<
    RecordTypeDescription<{
      [key: string]: T['_output'];
    }>
  >({
    description: {
      type: 'record',
      keyType: keyType,
      valueType: valueType,
    },
  });
