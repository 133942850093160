import { CustomError } from '../custom-error.js';

export function getOrCreate<T>(
  map: { [key: string]: T },
  key: string,
  create: () => T,
): T {
  const existing = map[key];
  if (existing === undefined) {
    const value = create();
    map[key] = value;
    return value;
  }
  return existing;
}

export function setIfEmpty<T>(
  map: { [key: string]: T },
  key: string,
  value: T,
) {
  const existing = map[key];
  if (existing) {
    throw new CustomError('key already exists', null, { key });
  }
  map[key] = value;
}

export function getOrCreateRecursive<T>(
  map: { [key: string]: T },
  key: string,
  create: () => T,
  props: string[],
): T {
  const existing = map[key];
  if (existing === undefined) {
    let lazyValue: undefined | any = undefined;
    const proxy = new Proxy(
      {},
      {
        has(target: {}, p: string | symbol) {
          return props.indexOf(String(p)) !== -1;
        },
        get(target: {}, p: string | symbol): any {
          if (!lazyValue) {
            lazyValue = create();
          }
          return lazyValue[p];
        },
      },
    ) as T;
    map[key] = proxy;
    return proxy;
  }
  return existing;
}
