import { createType } from './create-type.js';
import { UnionTypeDescription } from './union-type-description.js';
import { TypeDescription } from './type-description.js';

//export const union = <T extends [Type<any, any>, ...Type<any, any>[]]>(
export const union = <T extends TypeDescription[]>(name: string, kinds: T) =>
  createType<UnionTypeDescription<T[number]['_output']>>({
    description: {
      type: 'union',
      unionTypes: kinds,
      name,
    },
  });
