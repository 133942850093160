import {
  ChangeDetectionStrategy,
  Component,
  input,
  Input,
} from '@angular/core';
import { Todo, TodoState } from '../../state/todo/state';
import { TodoEvents } from '../../state/todo/events';
import { StreamInterface } from '@aion/core/management/stream-interface.js';
import { timedSignal } from '@aion/core/utils/timedSignal.js';

@Component({
  selector: 'app-todo-list-item',
  templateUrl: './todo-list-item.component.html',
  styleUrls: ['./todo-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodoListItemComponent {
  id = input.required<string>();
  todo = input.required<Todo>();

  stream = input.required<StreamInterface<TodoState, TodoEvents>>();

  async updateCompletion(evt: Event) {
    if (!evt.target) {
      return;
    }
    const target = evt.target;
    if (!(target instanceof HTMLInputElement)) {
      return;
    }
    if (target.checked) {
      await this.stream().push(
        'complete-todo',
        { id: this.id() },
        { signal: timedSignal() },
      );
    } else {
      await this.stream().push(
        'incomplete-todo',
        { id: this.id() },
        { signal: timedSignal() },
      );
    }
  }
}
