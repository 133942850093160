import { MultiStateDefinitions } from './multi-state-definitions.js';
import { MultiStorage } from './multi-storage.js';
import { StorageClient } from './tracker/tracked-state.js';
import { createMultiTrackedStorage } from './create-multi-tracked-storage.js';
import { MultiStorageTransactionClient } from './multi-storage-transaction-client.js';
import { TransactionClient } from './storage-interface.js';
import { createMultiTransactionClient } from './create-multi-transaction-client.js';
import { createMultiPartState } from './map-multi-state-declaration.js';
import { CustomError } from '../custom-error.js';
import { StorageBuilder } from './tracker/storage-builder.js';

export function getStringObjectValue(
  value: Record<string, string | number | Date | boolean>,
): Record<string, string> {
  return Object.entries(value).reduce<Record<string, string>>(
    (map, [key, value]) => {
      if (typeof value === 'string') {
        map[key] = value;
      } else if (typeof value === 'number') {
        map[key] = value.toString();
      } else if (typeof value === 'boolean') {
        map[key] = value.toString();
      } else if (value instanceof Date) {
        map[key] = value.getTime().toString();
      } else {
        throw new CustomError('unexpected value', null, {
          key,
        });
      }
      return map;
    },
    {},
  );
}

export function createMultiStorage<TState extends MultiStateDefinitions>(
  storageBuilder: StorageBuilder,
  name: string,
  state: TState,
  signal: AbortSignal,
): MultiStorage<TState> {
  const multiStorageState = createMultiPartState(state);
  const storage = storageBuilder.open(name, multiStorageState, signal);

  return {
    storageName: name,
    storage<K extends keyof TState & string>(key: K): StorageClient<TState[K]> {
      return createMultiTrackedStorage(key, storage);
    },
    transaction<T>(
      fn: (client: MultiStorageTransactionClient<TState>) => Promise<T>,
      signal: AbortSignal,
    ): Promise<T> {
      return storage.transaction(async (client) => {
        return await fn({
          storage<K extends keyof TState & string>(
            key: K,
          ): TransactionClient<TState[K]> {
            return createMultiTransactionClient<TState[K]>(key, client as any);
          },
        });
      }, signal);
    },
  };
}
