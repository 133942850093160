<div class="flex flex-col justify-end space-y-3">
  <button (click)="loginWithMicrosoft()" class="">Login with Microsoft</button>

  <button (click)="loginWithGoogle()" class=" ">Login with Google</button>

  <button (click)="registerWithCustom('demo', '1234')" class="">
    Register with Custom
  </button>

  <button (click)="loginWithCustom('demo', '1234')" class="">
    Login with Custom
  </button>
</div>
