import { completeTodo } from './complete-todo/complete-todo';
import { incompleteTodo } from './incomplete-todo/incomplete-todo';
import { todoEvents } from './events';
import { todoState } from './state';
import { todoOutputs } from './outputs';
import { todoRealm } from '../realm';
import { createTodo } from './create-todo/create-todo';
import { updateTodo } from './update-todo/update-todo';
import { defineStream } from '@aion/core/realm/define-stream.js';

export const todoHandles = {
  'create-todo': createTodo,
  'update-todo': updateTodo,
  'complete-todo': completeTodo,
  'incomplete-todo': incompleteTodo,
} as const;

export const todoStream = defineStream(todoRealm, {
  events: todoEvents,
  state: todoState,
  outputs: todoOutputs,
  pattern: 'personal-$(auth.sub)',
  handle: todoHandles,
});
