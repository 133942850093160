import { EventState } from '../event/event-state.js';
import { AuthManagementState } from './auth/auth-management-state.js';
import { createMultiStorage } from '../storage/create-multi-storage.js';
import { MultiStorage } from '../storage/multi-storage.js';
import { TenantState } from './tenant-storage-factory.js';
import { PartitionBuilder } from './create-partition-storage.js';
import { string } from '../typing/string.js';

export function createTenantStorage(
  storageBuilder: PartitionBuilder,
  tenant: string,
  signal: AbortSignal,
): MultiStorage<TenantState> {
  return createMultiStorage(
    storageBuilder.create([{ name: 'tenant', type: string(), value: tenant }]),
    'tenants',
    {
      event: EventState,
      auth: AuthManagementState,
    },
    signal,
  );
}
