import {
  StateDefinitions,
  StateKeyValues,
  TypeOfStateDeclaration,
} from '../storage/state-declaration.js';
import { Lazy } from './lazy.js';
import { GetOptions, SnapshotClient } from '../storage/snapshot-client.js';
import { CustomError } from '../custom-error.js';
import {
  RangeOptions,
  SnapshotIndexClient,
} from '../storage/snapshot-index-client.js';
import { RangeResult } from '../storage/browse-result.js';
import { createSnapshotIndex } from './create-snapshot-index.js';
import { lazyWrap } from './lazy-wrap.js';
import { StorageView } from '../storage/tracker/tracked-state.js';

export function createSnapshot<
  TState extends StateDefinitions,
  K extends string & keyof TState,
>(
  lazyStorage: Lazy<StorageView<TState>>,
  state: TState,
  stateName: K,
): SnapshotClient<TState[K]> {
  const definition = state[stateName];
  if (!definition) {
    throw new CustomError(`state not found`, null, {
      state: stateName,
    });
  }

  return {
    async *range(
      bookmark: StateKeyValues<TState[K]> | null,
      opts: RangeOptions,
    ): AsyncGenerator<RangeResult<TypeOfStateDeclaration<TState[K]>>> {
      const storage = await lazyStorage.resolve();
      yield* storage.snapshot(stateName).range(bookmark, opts);
    },
    index<I extends keyof TState[K]['indices'] & string>(
      index: I,
    ): SnapshotIndexClient<TState[K], TState[K]['indices'][I]> {
      return createSnapshotIndex(
        definition.indices[index],
        0,
        lazyWrap(lazyStorage, (storage) =>
          storage.snapshot(stateName).index(index),
        ),
      );
    },
    async get(
      id: StateKeyValues<TState[K]>,
      opts: GetOptions,
    ): Promise<TypeOfStateDeclaration<TState[K]> | null> {
      const storage = await lazyStorage.resolve();
      return storage.snapshot(stateName).get(id, opts);
    },
  };
}
