import { record } from '../typing/record.js';
import { string } from '../typing/string.js';
import { object } from '../typing/object.js';
import { array } from '../typing/array.js';
import { TypeOf } from '../typing/type-of.js';
import { boolean } from '../typing/boolean.js';
import { JsonSchemaDescription } from '../rpc/server/openapi/json-schema-description.js';

export const StateDeclarationIndex = record(
  string(),
  object('StateDeclarationIndex', {
    fields: array(string()),
    unique: boolean(),
  }),
);
export type StateDeclarationIndex = TypeOf<typeof StateDeclarationIndex>;

export const StreamState = object('StreamState', {
  schema: JsonSchemaDescription,
  indices: StateDeclarationIndex,
  key: array(string()),
});

export type StreamState = TypeOf<typeof StreamState>;
