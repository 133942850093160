export function getError(e: unknown): string {
  if (typeof e === 'string') {
    return e;
  } else if (e instanceof Error) {
    return e.message;
  } else {
    return `${e}`;
  }
}

export function getErrorStackTrace(e: unknown): string | undefined {
  if (typeof e === 'string') {
    return undefined;
  } else if (e instanceof Error) {
    return e.stack ?? undefined;
  } else {
    return undefined;
  }
}
