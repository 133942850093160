export function combineAbortSignal(first: AbortSignal, second: AbortSignal) {
  const ctrl = new AbortController();

  const firstListener = () => {
    ctrl.abort();
    second.removeEventListener('abort', secondListener);
  };

  const secondListener = () => {
    ctrl.abort();
    first.removeEventListener('abort', firstListener);
  };

  first.addEventListener('abort', firstListener, { once: true });
  second.addEventListener('abort', secondListener, { once: true });

  return ctrl.signal;
}
