import { TypeDescription } from '../../../typing/type-description.js';
import { failNever } from '../../../utils/fail-never.js';
import { CustomError } from '../../../custom-error.js';
import { JsonSchemaDescription } from './json-schema-description.js';
import { getOrFail } from '../../../realm/get-or-fail.js';
import { setIfEmpty } from '../../../utils/get-or-create.js';
import { union } from '../../../typing/union.js';
import { date } from '../../../typing/date.js';
import { string } from '../../../typing/string.js';
import { literal } from '../../../typing/literal.js';
import { boolean } from '../../../typing/boolean.js';
import { number } from '../../../typing/number.js';
import { array } from '../../../typing/array.js';
import { record } from '../../../typing/record.js';
import { optional } from '../../../typing/optional.js';
import { object } from '../../../typing/object.js';

export interface JsonRefSchema {
  $ref: string;
}

export interface JsonStringSchema {
  type: 'string';
  format?: 'date-time';
  enum?: string[];
}

export interface JsonNumberSchema {
  type: 'number';
  enum?: number[];
}

export interface JsonBooleanSchema {
  type: 'boolean';
  enum?: boolean[];
}

export interface JsonArraySchema {
  type: 'array';
  items: {
    type: JsonSchema;
  };
}

export interface JsonObjectSchema {
  type: 'object';
  additionalProperties?: JsonSchema;
  properties?: Record<string, JsonSchema>;
  required?: string[];
}

export interface JsonOneOfSchema {
  oneOf: JsonSchema[];
}

export const isJsonRef = (val: JsonSchema): val is JsonRefSchema =>
  typeof val === 'object' && !!val && '$ref' in val;

export const isJsonOneOf = (val: JsonSchema): val is JsonOneOfSchema =>
  typeof val === 'object' && !!val && 'oneOf' in val;

export type JsonSchema =
  | JsonStringSchema
  | JsonNumberSchema
  | JsonBooleanSchema
  | JsonArraySchema
  | JsonObjectSchema
  | JsonOneOfSchema
  | JsonRefSchema;

export function getJsonSchemaDescription(
  type: TypeDescription,
): JsonSchemaDescription {
  const schemas: Record<string, JsonSchema> = {};
  const mainSchema = getJsonSchema(schemas, type);
  return {
    schemas,
    mainSchema,
  };
}

export function getTypeDescriptionFromJsonSchemaDescription(
  jsonSchema: JsonSchemaDescription,
): TypeDescription {
  const types: Record<string, TypeDescription> = {};
  return getTypeSchema(types, jsonSchema.mainSchema, null, jsonSchema.schemas);
}

function getTypeSchema(
  types: Record<string, TypeDescription>,
  schema: JsonSchema,
  name: string | null,
  schemas: Record<string, JsonSchema>,
): TypeDescription {
  if (isJsonOneOf(schema)) {
    return union(
      name ?? '',
      schema.oneOf.map((unionSchema) =>
        getTypeSchema(types, unionSchema, null, schemas),
      ),
    );
  } else if (isJsonRef(schema)) {
    const name = schema.$ref.substring('#/components/schemas/'.length);
    if (types[name]) {
      return types[name];
    }

    const namedSchema = getOrFail(schemas, name);
    const fakeType: TypeDescription = {} as any;
    types[name] = fakeType;
    const realType = getTypeSchema(types, namedSchema, name, schemas);
    for (const [key, value] of Object.entries(realType)) {
      (fakeType as any)[key] = value;
    }
    return fakeType;
  } else if (schema.type === 'string') {
    if (schema.format === 'date-time') {
      return date();
    } else if (schema.enum) {
      const first = schema.enum[0];
      if (first && schema.enum.length === 1) {
        return literal(first);
      } else if (schema.enum.length > 1) {
        return union(
          name ?? '',
          schema.enum.map((e) => literal(e)),
        );
      } else {
        throw new CustomError('empty string enum is not allowed', null, {});
      }
    } else {
      return string();
    }
  } else if (schema.type === 'number') {
    if (schema.enum) {
      const first = schema.enum[0];
      if (first && schema.enum.length === 1) {
        return literal(first);
      } else if (schema.enum.length > 1) {
        return union(
          name ?? '',
          schema.enum.map((e) => literal(e)),
        );
      } else {
        throw new CustomError('empty number enum is not allowed', null, {});
      }
    } else {
      return number();
    }
  } else if (schema.type === 'boolean') {
    if (schema.enum) {
      const first = schema.enum[0];
      if (first !== null && first !== undefined && schema.enum.length === 1) {
        return literal(first);
      } else if (schema.enum.length > 1) {
        return union(
          name ?? '',
          schema.enum.map((e) => literal(e)),
        );
      } else {
        throw new CustomError('empty boolean enum is not allowed', null, {});
      }
    } else {
      return boolean();
    }
  } else if (schema.type === 'array') {
    return array(getTypeSchema(types, schema.items.type, null, schemas));
  } else if (schema.type === 'object') {
    if (schema.properties && !schema.additionalProperties) {
      const required = schema.required ?? [];
      const properties = Object.entries(schema.properties).reduce<
        Record<string, TypeDescription>
      >((map, [propName, prop]) => {
        const propType = getTypeSchema(types, prop, null, schemas);
        map[propName] =
          required.indexOf(propName) === -1 ? optional(propType) : propType;
        return map;
      }, {});
      return object(name ?? '', properties);
    } else if (schema.additionalProperties && !schema.properties) {
      return record(
        string(),
        getTypeSchema(types, schema.additionalProperties, null, schemas),
      );
    } else {
      throw new CustomError(
        'unknown object, only properties or additionalProperties is allowed',
        null,
        {},
      );
    }
  } else {
    failNever(schema, 'unknown json schema');
  }
}

export function getJsonSchema(
  schemas: Record<string, JsonSchema>,
  type: TypeDescription,
): JsonSchema {
  if (type.type === 'string') {
    return {
      type: 'string',
    };
  } else if (type.type === 'number') {
    return {
      type: 'number',
    };
  } else if (type.type === 'boolean') {
    return {
      type: 'boolean',
    };
  } else if (type.type === 'numberAsText') {
    return {
      type: 'string',
    };
  } else if (type.type === 'array') {
    return {
      type: 'array',
      items: {
        type: getJsonSchema(schemas, type.itemType),
      },
    };
  } else if (type.type === 'date') {
    return {
      type: 'string',
      format: 'date-time',
    };
  } else if (type.type === 'literal') {
    if (typeof type.constant === 'string') {
      return {
        type: 'string',
        enum: [type.constant],
      };
    } else if (typeof type.constant === 'number') {
      return {
        type: 'number',
        enum: [type.constant],
      };
    } else if (typeof type.constant === 'boolean') {
      return {
        type: 'boolean',
        enum: [type.constant],
      };
    } else {
      failNever(type.constant, 'unknown literal');
    }
  } else if (type.type === 'union') {
    if (!schemas[type.name]) {
      schemas[type.name] = {
        oneOf: type.unionTypes.map((u) => getJsonSchema(schemas, u)),
      };
    }

    // TODO check for override
    return {
      $ref: `#/components/schemas/${type.name}`,
    };
  } else if (type.type === 'record') {
    return {
      type: 'object',
      additionalProperties: getJsonSchema(schemas, type.valueType),
    };
  } else if (type.type === 'object') {
    if (!schemas[type.name]) {
      const schema: {
        type: 'object';
        properties: Record<string, any>;
        required: string[];
      } = {
        type: 'object',
        properties: {},
        required: [],
      };

      setIfEmpty(schemas, type.name, schema);

      for (const [propName, prop] of Object.entries(type.object)) {
        schema.properties[propName] = getJsonSchema(schemas, prop);
        if (prop.type !== 'optional') {
          schema.required.push(propName);
        }
      }
    }
    return {
      $ref: `#/components/schemas/${type.name}`,
    };
  } else if (type.type === 'optional') {
    return getJsonSchema(schemas, type.optionalType);
  } else if (type.type === 'nullable') {
    return getJsonSchema(schemas, type.nullableType);
  } else if (type.type === 'func') {
    throw new CustomError('unable to convert func to json schema', null, {});
  } else if (type.type === 'promise') {
    throw new CustomError('unable to convert promise to json schema', null, {});
  } else {
    failNever(type, 'unknown type description');
  }
}
