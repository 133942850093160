import { createType } from './create-type.js';
import { NullableTypeDescription } from './nullable-type-description.js';
import { TypeDescription } from './type-description.js';

export const nullable = <T extends TypeDescription>(type: T) =>
  createType<NullableTypeDescription<T['_output']>>({
    description: {
      type: 'nullable',
      nullableType: type,
    },
  });
