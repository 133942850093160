import {
  StateDefinition,
  StateDefinitions,
  StateIndices,
  StateKeyValues,
  TypeOfStateDeclaration,
} from '../storage/state-declaration.js';
import { RangeResult } from '../storage/browse-result.js';
import {
  FilterKey,
  FilterStateIndex,
  FilterValue,
  RangeOptions,
  SnapshotIndexClient,
} from '../storage/snapshot-index-client.js';
import {
  TransactionClient,
  TransactionStateClient,
} from '../storage/storage-interface.js';
import { GetOptions, SnapshotClient } from '../storage/snapshot-client.js';
import {
  DeleteType,
  PatchType,
  StateClient,
  UpsertType,
} from '../storage/state-client.js';
import { StorageClient } from '../storage/tracker/tracked-state.js';
import { StorageBuilder } from '../storage/tracker/storage-builder.js';
import { StringTypeDescription } from '../typing/string-type-description.js';
import { NumberTypeDescription } from '../typing/number-type-description.js';
import { BooleanTypeDescription } from '../typing/boolean-type-description.js';
import { DateTypeDescription } from '../typing/date-type-description.js';
import { object } from '../typing/object.js';
import { TypeDescription } from '../typing/type-description.js';
import { getJsonSchemaDescription } from '../rpc/server/openapi/json-schema.js';
import { getPartitionValueObject } from './get-partition-value-object.js';
import { replacePlaceholders } from '../stream/replace-placeholders.js';
import { getStringObjectValue } from '../storage/create-multi-storage.js';

export type PartitionKey =
  | {
      name: string;
      type: StringTypeDescription;
      value: string;
    }
  | {
      name: string;
      type: NumberTypeDescription;
      value: number;
    }
  | {
      name: string;
      type: BooleanTypeDescription;
      value: boolean;
    }
  | {
      name: string;
      type: DateTypeDescription;
      value: Date;
    };

function partitionState<TState extends StateDefinitions>(
  state: TState,
  partitionKeys: PartitionKey[],
): TState {
  return Object.entries(state).reduce<StateDefinitions>((map, [key, value]) => {
    const type = object(value.type.name, {
      ...Object.entries(value.type.object).reduce<
        Record<string, TypeDescription>
      >((obj, [key, prop]) => {
        obj[`partition_${key}`] = prop;
        return obj;
      }, {}),
      ...partitionKeys.reduce<Record<string, TypeDescription>>((map, k) => {
        map[k.name] = k.type;
        return map;
      }, {}),
    });

    map[key] = {
      type,
      schema: getJsonSchemaDescription(type),
      key: [
        ...partitionKeys.map((k) => k.name),
        ...value.key.map((k) => `partition_${k}`),
      ],
      indices: Object.entries(value.indices as StateIndices<any>).reduce<
        StateIndices<any>
      >(
        (indexMap, [indexKey, indexValue]) => {
          indexMap[`partition_${indexKey}`] = {
            fields: [
              ...partitionKeys.map((k) => k.name),
              ...(indexValue.fields as string[]).map((k) => `partition_${k}`),
            ],
            unique: indexValue.unique,
          };
          return indexMap;
        },
        {
          range: {
            fields: [...partitionKeys.map((k) => k.name)],
            unique: false,
          },
        },
      ),
    };
    return map;
  }, {}) as TState;
}

function mapParitioned(data: any): any {
  if (!data) {
    return null;
  }
  return Object.entries(data).reduce<Record<string, any>>(
    (map, [key, data]) => {
      if (key.startsWith('partition_')) {
        map[key.substring('partition_'.length)] = data;
      }
      return map;
    },
    {},
  );
}
function toParitioned(data: any): any {
  if (data === null) {
    return null;
  }
  if (data === undefined) {
    return undefined;
  }
  return Object.entries(data).reduce<Record<string, any>>(
    (map, [key, data]) => {
      map['partition_' + key] = data;
      return map;
    },
    {},
  );
}

function createPartitionedIndex<TState extends StateDefinition<any, any, any>>(
  client: SnapshotIndexClient<TState, any>,
): SnapshotIndexClient<TState, any> {
  return {
    filter(
      key: FilterKey<any>,
      value: FilterValue<TState, any>,
    ): SnapshotIndexClient<TState, FilterStateIndex<any>> {
      return createPartitionedIndex(client.filter(`partition_${key}`, value));
    },
    async *range(
      bookmark: FilterValue<TState, any> | null,
      opts: RangeOptions,
    ): AsyncGenerator<RangeResult<TypeOfStateDeclaration<TState>>> {
      for await (const result of client.range(bookmark, opts)) {
        yield {
          keys: mapParitioned(result.keys),
          data: mapParitioned(result.data),
        };
      }
    },
  };
}

function createPartitionedTransactionClient<TState extends StateDefinitions>(
  state: TransactionClient<TState>,
  partitionKeys: PartitionKey[],
): TransactionClient<TState> {
  return {
    state<K extends string & keyof TState>(
      name: K,
    ): TransactionStateClient<TState[K]> {
      return createPartitionedStorageClient(state.state(name), partitionKeys);
    },
    snapshot<K extends string & keyof TState>(
      name: K,
    ): SnapshotClient<TState[K]> {
      return createPartitionedSnapshot(state.snapshot(name), partitionKeys);
    },
  };
}

function createPartitionedStateClient<
  TState extends StateDefinition<any, any, any>,
>(
  state: StateClient<TState>,
  partitionValues: PartitionKey[],
): StateClient<TState> {
  return {
    patch(val: PatchType<TState>, signal): Promise<void> {
      return state.patch(
        {
          ...toParitioned(val),
          ...getPartitionValueObject(partitionValues),
        },
        signal,
      );
    },
    // clear(): Promise<void> {
    //   return state.clear({
    //     ...id,
    //     ...partitionValues,
    //   });
    // },
    delete(id: DeleteType<TState>, signal): Promise<void> {
      return state.delete(
        {
          ...toParitioned(id),
          ...getPartitionValueObject(partitionValues),
        },
        signal,
      );
    },
    insert(val: TypeOfStateDeclaration<TState>, signal): Promise<void> {
      return state.insert(
        {
          ...toParitioned(val),
          ...getPartitionValueObject(partitionValues),
        },
        signal,
      );
    },
    upsert(val: UpsertType<TState>, signal): Promise<void> {
      return state.upsert(
        {
          ...toParitioned(val),
          ...getPartitionValueObject(partitionValues),
        },
        signal,
      );
    },
  };
}

function createPartitionedStorageClient<
  TState extends StateDefinition<any, any, any>,
>(
  state: TransactionStateClient<TState>,
  partitionValues: PartitionKey[],
): TransactionStateClient<TState> {
  return {
    patch(val: PatchType<TState>): Promise<void> {
      return state.patch({
        ...toParitioned(val),
        ...getPartitionValueObject(partitionValues),
      });
    },
    // clear(): Promise<void> {
    //   return state.clear({
    //     ...id,
    //     ...partitionValues,
    //   });
    // },
    delete(id: DeleteType<TState>): Promise<void> {
      return state.delete({
        ...toParitioned(id),
        ...getPartitionValueObject(partitionValues),
      });
    },
    insert(val: TypeOfStateDeclaration<TState>): Promise<void> {
      return state.insert({
        ...toParitioned(val),
        ...getPartitionValueObject(partitionValues),
      });
    },
    upsert(val: UpsertType<TState>): Promise<void> {
      return state.upsert({
        ...toParitioned(val),
        ...getPartitionValueObject(partitionValues),
      });
    },
  };
}

function createPartitionedSnapshot<
  TState extends StateDefinition<any, any, any>,
>(
  state: SnapshotClient<TState>,
  partitionKeys: PartitionKey[],
): SnapshotClient<TState> {
  return {
    async get(
      id: StateKeyValues<TState>,
      opts: GetOptions,
    ): Promise<TypeOfStateDeclaration<TState> | null> {
      return mapParitioned(
        await state.get(
          {
            ...toParitioned(id),
            ...getPartitionValueObject(partitionKeys),
          },
          opts,
        ),
      );
    },
    index<K extends keyof TState['indices'] & string>(
      index: K,
    ): SnapshotIndexClient<TState, TState['indices'][K]> {
      let client = state.index(`partition_${index}`);
      for (const key of partitionKeys) {
        client = client.filter(key.name as any, key.value as any);
      }
      return createPartitionedIndex(client);
    },
    async *range(
      bookmark: StateKeyValues<TState> | null,
      opts: RangeOptions,
    ): AsyncGenerator<RangeResult<TypeOfStateDeclaration<TState>>> {
      let client = state.index('range');
      for (const key of partitionKeys) {
        client = client.filter(key as any, key.value as any);
      }
      for await (const item of client.range(bookmark as any, opts)) {
        yield {
          data: mapParitioned(item.data),
          keys: mapParitioned(item.keys),
        };
      }
    },
  };
}

export interface PartitionBuilder {
  create(partitionKeys: PartitionKey[]): StorageBuilder;
}

export function createPartitionStorageBuilder(
  storageBuilder: StorageBuilder,
): PartitionBuilder {
  return {
    create(partitionKeys: PartitionKey[]): StorageBuilder {
      return {
        ...storageBuilder,
        open<TState extends StateDefinitions>(
          name: string,
          state: TState,
          signal: AbortSignal,
        ): StorageClient<TState> {
          // TODO remove partitionKeys used
          const templatedName = replacePlaceholders(
            name,
            getStringObjectValue(getPartitionValueObject(partitionKeys)),
          );

          const storage = storageBuilder.open(
            templatedName.value,
            partitionState(state, partitionKeys),
            signal,
          );
          return getPartitionedStorage(storage, partitionKeys);
        },
      };
    },
  };
}

function getPartitionedStorage<TState extends StateDefinitions>(
  storage: StorageClient<TState>,
  partitionKeys: PartitionKey[],
): StorageClient<TState> {
  return {
    snapshot<K extends string & keyof TState>(
      name: K,
    ): SnapshotClient<TState[K]> {
      return createPartitionedSnapshot(storage.snapshot(name), partitionKeys);
    },
    transaction<T>(
      fn: (client: TransactionClient<TState>) => Promise<T>,
      signal: AbortSignal,
    ): Promise<T> {
      return storage.transaction((trx) => {
        return fn(createPartitionedTransactionClient(trx, partitionKeys));
      }, signal);
    },
    state<K extends string & keyof TState>(name: K): StateClient<TState[K]> {
      return createPartitionedStateClient(storage.state(name), partitionKeys);
    },
  };
}
