import { ReducedInstanceState } from './instance-storage.js';
import { StoredEvent } from '../event/stored-event.js';
import { RuntimeEnv } from '../runtime-env.js';
import { InstanceIdentifier } from './instance-identifier.js';
import { StateDefinitions } from '../storage/state-declaration.js';
import { HostRuntimeExecution } from '../runtime/host-runtime.js';
import { MultiStorage } from '../storage/multi-storage.js';
import {
  HostPushFailResult,
  RuntimeResult,
} from '../runtime/runtime-result.js';
import { createTrackedStorage } from '../storage/tracker/track-storage.js';
import { Queue } from '../queue/queue.js';

class UnsuccessfulExecution extends Error {
  constructor(public result: HostPushFailResult) {
    super();
  }
}

export async function applyRuntimeResultToInstance<
  TState extends StateDefinitions,
>(
  runtime: HostRuntimeExecution<any>,
  streamStorage: {
    storage: MultiStorage<ReducedInstanceState<TState>>;
    state: TState;
    queue: Queue;
  },
  streamId: InstanceIdentifier,
  evt: StoredEvent,
  env: RuntimeEnv,
  seed: string,
  signal: AbortSignal,
): Promise<RuntimeResult> {
  const random = await env.sha1(seed + streamId.name + evt.id);

  try {
    return await streamStorage.storage.transaction(async (trx) => {
      const trackedState = createTrackedStorage(
        trx.storage('state'),
        streamStorage.storage.storageName,
        streamStorage.state,
        streamStorage.queue,
      );

      const result = await runtime.execute(
        trackedState,
        evt,
        {
          text: random,
        },
        env,
        signal,
      );

      if (!result.success) {
        throw new UnsuccessfulExecution(result);
      }

      await trx.storage('processor').state('operations').insert({
        realm: streamId.realm,
        pattern: streamId.pattern,
        version: streamId.version,
        name: streamId.name,
        tenant: streamId.tenant,
        eventId: evt.id,
        result,
        appliedAt: new Date(),
        eventAt: evt.createdAt,
      });

      await trx.storage('processor').state('eventSequence').insert({
        realm: streamId.realm,
        pattern: streamId.pattern,
        version: streamId.version,
        name: streamId.name,
        tenant: streamId.tenant,
        eventId: evt.id,
        appliedAt: new Date(),
        error: undefined,
      });

      return result;
    }, signal);
  } catch (e) {
    if (e instanceof UnsuccessfulExecution) {
      await streamStorage.storage
        .storage('processor')
        .state('operations')
        .insert(
          {
            realm: streamId.realm,
            pattern: streamId.pattern,
            version: streamId.version,
            name: streamId.name,
            tenant: streamId.tenant,
            eventId: evt.id,
            result: e.result,
            appliedAt: new Date(),
            eventAt: evt.createdAt,
          },
          signal,
        );

      return e.result;
    } else {
      throw e;
    }
  }
}
