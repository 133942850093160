import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TodoPageComponent } from './todo-page/todo-page.component';
import { GroupCreateModalComponent } from './group-create-modal/group-create-modal.component';
import { LoginComponent } from './login/login.component';
import { loginGuard } from './login.guard';
import { unauthenticatedGuard } from './unauthenticated-guard';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [unauthenticatedGuard],
  },
  {
    path: ':group',
    component: TodoPageComponent,
    canActivate: [loginGuard],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'personal',
  },
  {
    outlet: 'modal',
    path: 'create-group',
    component: GroupCreateModalComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
