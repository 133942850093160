export function* getPlaceholderNames(
  pattern: string,
): Generator<{ name: string; index: number; match: string }> {
  const placeholderRegex = /\$\((?<name>.+?)\)/g;
  for (const group of pattern.matchAll(placeholderRegex)) {
    if (!group.groups) {
      continue;
    }

    const name = group.groups['name'];
    if (name) {
      yield {
        name,
        index: group.index,
        match: group[0],
      };
    }
  }
}
