import { string } from '@aion/core/typing/string.js';
import { TypeOfEvent } from '@aion/core/typing/type-of.js';
import { declareEvent } from '@aion/core/storage/declare-state.js';

export const EmailSendEventV2 = declareEvent({
  to: string(),
  subject: string(),
  cc: string(),
});
export type EmailSendEventV2 = TypeOfEvent<typeof EmailSendEventV2>;

export const emailEvents = {
  'email-send': EmailSendEventV2,
};

export type EmailEvents = typeof emailEvents;
