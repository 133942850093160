import { defineHttpRpc } from '@aion/core/rpc/server/http-rpc.js';
import { rpcApiDefinition } from './rpc-api-definition.js';

export const rpcApiHttpDefinition = defineHttpRpc(rpcApiDefinition, {
  'auth.login': {
    method: 'post',
    path: '/api/{tenant}/{realm}/auth/login',
    mapping: {
      tenant: { type: 'path' },
      realm: { type: 'path' },
      username: { type: 'body' },
      password: { type: 'body' },
      provider: { type: 'body' },
    },
    openapi: {
      operationId: 'authLogin',
      description: 'Login to an account',
      tags: ['auth'],
    },
  },
  'auth.authorize': {
    method: 'post',
    path: `/api/{tenant}/{realm}/auth/token`,
    mapping: {
      tenant: { type: 'path' },
      realm: { type: 'path' },
      provider: { type: 'body' },
      code: { type: 'body' },
      redirectUri: { type: 'body' },
    },
    openapi: {
      operationId: 'authToken',
      description: 'Claim a token for an account',
      tags: ['auth'],
    },
  },
  'auth.register': {
    method: 'post',
    path: `/api/{tenant}/{realm}/auth/register`,
    mapping: {
      tenant: { type: 'path' },
      realm: { type: 'path' },
      username: { type: 'body' },
      password: { type: 'body' },
      provider: { type: 'body' },
    },
    openapi: {
      operationId: 'authRegister',
      description: 'Register an account',
      tags: ['auth'],
    },
  },
  'auth.refresh': {
    method: 'post',
    path: `/api/{tenant}/{realm}/auth/refresh`,
    mapping: {
      tenant: { type: 'path' },
      realm: { type: 'path' },
      refreshToken: { type: 'body' },
      provider: { type: 'body' },
      username: { type: 'body' },
    },
    openapi: {
      operationId: 'authRefresh',
      description: 'Refresh an access token',
      tags: ['auth'],
    },
  },
  'event.get': {
    method: 'get',
    path: `/api/{tenant}/{realm}/stream/{pattern}/{version}/event/{id}`,
    mapping: {
      tenant: { type: 'path' },
      realm: { type: 'path' },
      type: { type: 'path' },
      pattern: { type: 'path' },
      version: { type: 'path' },
      id: { type: 'path' },
      token: { type: 'header' },
      args: { type: 'header' },
    },
    openapi: {
      tags: ['event'],
      operationId: 'getEvent',
      description: 'Get event from stream',
    },
  },
  'event.push': {
    method: 'post',
    path: `/api/{tenant}/{realm}/stream/{pattern}/{version}/event/{event}/{id}`,
    mapping: {
      tenant: { type: 'path' },
      realm: { type: 'path' },
      pattern: { type: 'path' },
      type: { type: 'path' },
      version: { type: 'path' },
      token: { type: 'header' },
      args: { type: 'header' },
      event: { type: 'path' },
      id: { type: 'path' },
      data: { type: 'body' },
      annotations: { type: 'body' },
      createdAt: { type: 'body' },
    },
    openapi: {
      tags: ['event'],
      operationId: 'pushEvent',
      description: 'Push event to stream',
    },
  },
  'state.browse': {
    method: 'post',
    path: `/api/{tenant}/{realm}/state/{pattern}/{version}/{state}/browse`,
    mapping: {
      tenant: { type: 'path' },
      realm: { type: 'path' },
      type: { type: 'path' },
      pattern: { type: 'path' },
      version: { type: 'path' },
      state: { type: 'path' },
      token: { type: 'header' },
      args: { type: 'header' },
      take: { type: 'body' },
      open: { type: 'body' },
      direction: { type: 'body' },
      bookmark: { type: 'body' },
    },
    openapi: {
      tags: ['state'],
      operationId: 'browseStateRecords',
      description: 'Get state record',
    },
  },
  'state.index.browse': {
    method: 'post',
    path: `/api/{tenant}/{realm}/state/{pattern}/{version}/{state}/index/{index}/browse`,
    mapping: {
      tenant: { type: 'path' },
      realm: { type: 'path' },
      type: { type: 'path' },
      pattern: { type: 'path' },
      version: { type: 'path' },
      state: { type: 'path' },
      index: { type: 'path' },
      token: { type: 'header' },
      args: { type: 'header' },
      take: { type: 'body' },
      open: { type: 'body' },
      direction: { type: 'body' },
      bookmark: { type: 'body' },
      filter: { type: 'body' },
    },
    openapi: {
      tags: ['state'],
      operationId: 'browseIndexRecords',
      description: 'Get state record',
    },
  },
  'state.index.browse.observe': {
    method: 'post',
    path: `/api/{tenant}/{realm}/state/{pattern}/{version}/{state}/index/{index}/observe/browse`,
    mapping: {
      tenant: { type: 'path' },
      realm: { type: 'path' },
      type: { type: 'path' },
      pattern: { type: 'path' },
      version: { type: 'path' },
      state: { type: 'path' },
      index: { type: 'path' },
      token: { type: 'header' },
      args: { type: 'header' },
      take: { type: 'body' },
      open: { type: 'body' },
      direction: { type: 'body' },
      bookmark: { type: 'body' },
      filter: { type: 'body' },
    },
    openapi: {
      tags: ['state'],
      description: 'Get state record',
      operationId: 'observeIndexRecords',
    },
  },
  'state.browse.observe': {
    method: 'post',
    path: `/api/{tenant}/{realm}/state/{pattern}/{version}/{state}/observe/browse`,
    mapping: {
      tenant: { type: 'path' },
      realm: { type: 'path' },
      type: { type: 'path' },
      pattern: { type: 'path' },
      version: { type: 'path' },
      state: { type: 'path' },
      token: { type: 'header' },
      args: { type: 'header' },
      take: { type: 'body' },
      open: { type: 'body' },
      direction: { type: 'body' },
      bookmark: { type: 'body' },
    },
    openapi: {
      tags: ['state'],
      description: 'Get state record',
      operationId: 'observeRecords',
    },
  },
  'state.get': {
    method: 'get',
    path: `/api/{tenant}/{realm}/state/{pattern}/{version}/{state}/get/{id}`,
    mapping: {
      tenant: { type: 'path' },
      realm: { type: 'path' },
      type: { type: 'query' },
      pattern: { type: 'path' },
      version: { type: 'path' },
      state: { type: 'path' },
      id: { type: 'path' },
      token: { type: 'header' },
      args: { type: 'header' },
    },
    openapi: {
      tags: ['state'],
      operationId: 'getStateRecord',
      description: 'Get state record',
    },
  },
  'state.get.observe': {
    method: 'get',
    path: `/api/{tenant}/{realm}/state/{pattern}/{version}/{state}/observe/get/{id}`,
    mapping: {
      tenant: { type: 'path' },
      realm: { type: 'path' },
      type: { type: 'query' },
      pattern: { type: 'path' },
      version: { type: 'path' },
      state: { type: 'path' },
      id: { type: 'path' },
      token: { type: 'header' },
      args: { type: 'header' },
    },
    openapi: {
      tags: ['state'],
      operationId: 'observeStateRecord',
      description: 'Observe state record',
    },
  },
  'state.iterate': {
    method: 'post',
    path: `/api/{tenant}/{realm}/state/{pattern}/{version}/{state}/iterate`,
    mapping: {
      tenant: { type: 'path' },
      realm: { type: 'path' },
      type: { type: 'path' },
      pattern: { type: 'path' },
      version: { type: 'path' },
      state: { type: 'path' },
      token: { type: 'header' },
      args: { type: 'header' },
      take: { type: 'body' },
      open: { type: 'body' },
      direction: { type: 'body' },
      bookmark: { type: 'body' },
    },
    openapi: {
      tags: ['state'],
      description: 'Iterate state record',
      operationId: 'iterateRecords',
    },
  },
  'state.index.iterate': {
    method: 'post',
    path: `/api/{tenant}/{realm}/state/{pattern}/{version}/{state}/index/{index}/iterate`,
    mapping: {
      tenant: { type: 'path' },
      realm: { type: 'path' },
      type: { type: 'path' },
      pattern: { type: 'path' },
      version: { type: 'path' },
      state: { type: 'path' },
      index: { type: 'path' },
      token: { type: 'header' },
      args: { type: 'header' },
      take: { type: 'body' },
      open: { type: 'body' },
      filter: { type: 'body' },
      direction: { type: 'body' },
      bookmark: { type: 'body' },
    },
    openapi: {
      tags: ['state'],
      operationId: 'iterateIndexRecords',
      description: 'Iterate state record',
    },
  },
});
