import {
  StateDefinition,
  TypeOfStateDeclaration,
} from '@aion/core/storage/state-declaration.js';
import { TypeDescription } from '@aion/core/typing/type-description.js';

export function parseData<T>(
  data: any,
  type: StateDefinition<T, any, any>,
): TypeOfStateDeclaration<T> {
  if (!data) {
    return data;
  }

  // TODO support deep object replace
  const desc: TypeDescription = type.type;
  if (desc.type === 'object') {
    const result: any = {};
    for (const [key, value] of Object.entries(
      desc.object as {
        [key: string]: TypeDescription;
      },
    )) {
      if (value.type === 'date' && typeof data[key] === 'string') {
        result[key] = new Date(data[key]);
      } else {
        result[key] = data[key];
      }
    }

    return result;
  } else {
    return data;
  }
}
