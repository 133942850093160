import { StorageClient } from './tracker/tracked-state.js';
import { StateClient } from './state-client.js';
import { SnapshotClient } from './snapshot-client.js';
import { TransactionClient } from './storage-interface.js';
import { createMultiTransactionClient } from './create-multi-transaction-client.js';

export function createMultiTrackedStorage(
  prefix: string,
  storage: StorageClient<any>,
): StorageClient<any> {
  return {
    state<K extends string>(name: K): StateClient<any> {
      return storage.state(`${prefix}-${name}`);
    },
    // observe<K extends string>(name: K): ObservedClient<any> {
    //   return storage.observe(`${prefix}-${name}`);
    // },
    snapshot<K extends string>(name: K): SnapshotClient<any> {
      return storage.snapshot(`${prefix}-${name}`);
    },
    transaction<T>(
      fn: (client: TransactionClient<any>) => Promise<T>,
      signal: AbortSignal,
    ): Promise<T> {
      return storage.transaction((client) => {
        return fn(createMultiTransactionClient(prefix, client));
      }, signal);
    },
  };
}
