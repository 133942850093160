import { string } from '@aion/core/typing/string.js';
import { optional } from '@aion/core/typing/optional.js';
import { union } from '@aion/core/typing/union.js';
import { literal } from '@aion/core/typing/literal.js';
import { date } from '@aion/core/typing/date.js';
import { declareState } from '@aion/core/storage/declare-state.js';
import { EventAuth } from '@aion/core/event/event-auth.js';
import { defineState } from '@aion/core/storage/define-state.js';
import { TypeOfEvent } from '@aion/core/typing/type-of.js';
import { todoState } from '../todo/state.js';

export const TodoGroup = declareState(
  {
    id: string(),
    name: string(),
    createdAt: date(),
  },
  ['id'] as const,
);
export type TodoGroup = TypeOfEvent<typeof TodoGroup>;

export const TodoGroupMember = declareState(
  {
    id: string(),
    name: optional(string()),
    email: optional(string()),
    state: union('TodoGroupState', [literal('invited'), literal('accepted')]),
    auth: EventAuth,
    createdAt: date(),
  },
  ['id'] as const,
).index('name', ['name']);

export const groupState = defineState({
  ...todoState,
  group: TodoGroup,
  groupMembers: TodoGroupMember,
});

export type GroupState = typeof groupState;
