export async function* awaitIteration<T>(
  start: Promise<void>,
  stop: () => void,
  genFn: () => AsyncGenerator<T>,
): AsyncGenerator<T> {
  await start;
  try {
    for await (const item of genFn()) {
      yield item;
    }
  } finally {
    stop();
  }
}
