import { createType } from './create-type.js';
import { ArrayTypeDescription } from './array-type-description.js';
import { TypeDescription } from './type-description.js';

function isLength(value: any): value is number {
  return typeof value === 'number' && value > -1 && value % 1 === 0;
}

export const isArray = (val: unknown): val is unknown[] =>
  typeof val === 'object' &&
  val !== null &&
  val.constructor.name === 'Array' &&
  'length' in val &&
  isLength(val.length);

export const array = <T extends TypeDescription>(itemType: T) =>
  createType<ArrayTypeDescription<T['_output']>>({
    description: {
      type: 'array',
      itemType: itemType,
    },
  });
