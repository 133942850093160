import { groupStream } from '../group/stream';
import { todoRealm } from '../realm';
import { string } from '@aion/core/typing/string.js';
import { date } from '@aion/core/typing/date.js';
import { boolean } from '@aion/core/typing/boolean.js';
import { declareState } from '@aion/core/storage/declare-state.js';
import { defineAggregation } from '@aion/core/realm/define-aggregation.js';
import { injectEvent } from '@aion/core/realm/inject-event.js';
import { defineState } from '@aion/core/storage/define-state.js';
import { TypeOfEvent } from '@aion/core/typing/type-of.js';

const TodoGroup = declareState(
  {
    id: string(),
    name: string(),
    createdAt: date(),
    accepted: boolean(),
  },
  ['id'] as const,
);

export type TodoGroup = TypeOfEvent<typeof TodoGroup>;

const aggState = defineState({
  groups: TodoGroup.index('name', ['name']),
});

export const personalGroupAggregation = defineAggregation(todoRealm, {
  pattern: 'groups-$(auth.sub)',
  state: aggState,
  events: {
    'accept-invite': injectEvent(groupStream, 'accept-invite'),
    'create-group': injectEvent(groupStream, 'create-group'),
  },
  handle: {
    'create-group': (evt, ctx) => {
      ctx.state('groups').insert({
        id: evt.groupId,
        name: evt.name,
        createdAt: ctx.metadata.createdAt,
        accepted: false,
      });
    },
    'accept-invite': (evt, ctx) => {
      ctx.state('groups').patch({
        id: evt.groupId,
        accepted: true,
      });
    },
  },
});
