import {
  StateDefinition,
  StateDefinitions,
  StateIndices,
} from '../state-declaration.js';
import { TypeDescription } from '../../typing/type-description.js';
import { JsonSchemaDescription } from '../../rpc/server/openapi/json-schema-description.js';
import {
  getJsonSchemaDescription,
  isJsonOneOf,
  isJsonRef,
  JsonArraySchema,
  JsonBooleanSchema,
  JsonNumberSchema,
  JsonObjectSchema,
  JsonOneOfSchema,
  JsonSchema,
  JsonStringSchema,
} from '../../rpc/server/openapi/json-schema.js';
import { getOrFail } from '../../realm/get-or-fail.js';
import { failNever } from '../../utils/fail-never.js';
import { CustomError } from '../../custom-error.js';

export function isCompatibleStateDefinitions(
  existing: StateDefinitions,
  expected: StateDefinitions,
): boolean {
  for (const [name, expectedState] of Object.entries(expected)) {
    const existingState = existing[name];
    if (!existingState) {
      continue;
    }

    if (!isCompatibleStateDefinition(existingState, expectedState)) {
      return false;
    }
  }

  return true;
}

export function isCompatibleStateDefinition(
  existing: StateDefinition<any, string, StateIndices<string[]>>,
  expected: StateDefinition<any, string, StateIndices<string[]>>,
): boolean {
  if (!equalArray(existing.key, expected.key)) {
    return false;
  }

  if (!isCompatibleTypeDescription(existing.type, expected.type)) {
    return false;
  }

  for (const [name, expectedIndex] of Object.entries(expected.indices)) {
    const existingIndex = existing.indices[name];
    if (!existingIndex) {
      return false;
    }

    if (!equalArray(existingIndex.fields, expectedIndex.fields)) {
      return false;
    }

    if (expectedIndex.unique !== existingIndex.unique) {
      return false;
    }
  }

  return true;
}

type NormalizedJsonType =
  | JsonStringSchema
  | JsonNumberSchema
  | JsonBooleanSchema
  | JsonArraySchema
  | JsonObjectSchema
  | JsonOneOfSchema;

function normalizeJsonSchema(
  schemaDescription: JsonSchemaDescription,
  schema: JsonSchema,
): {
  name: string | null;
  schema: NormalizedJsonType;
} {
  if (isJsonRef(schema)) {
    const refSchema = getOrFail(
      schemaDescription.schemas,
      schema.$ref.substring('#/components/schemas/'.length),
    );
    if (isJsonRef(refSchema)) {
      throw new CustomError('invalid ref in ref', null, {
        ref: schema.$ref,
      });
    }

    return {
      name: schema.$ref,
      schema: refSchema,
    };
  } else {
    return {
      schema,
      name: null,
    };
  }
}

type JsonPrimitive =
  | JsonStringSchema
  | JsonNumberSchema
  | JsonBooleanSchema
  | JsonArraySchema
  | JsonObjectSchema;

function isCompatibleEnum<T extends boolean | string | number>(
  existing: T[] | undefined,
  expected: T[] | undefined,
): boolean {
  if (existing === undefined) {
    if (expected === undefined) {
      return true;
    } else {
      return false;
    }
  } else {
    if (expected === undefined) {
      return true;
    }

    return existing.every(
      (existingValue) => expected.indexOf(existingValue) >= 0,
    );
  }
}

function isCompatiblePrimitiveSchema(
  existingSchemaDescription: JsonSchemaDescription,
  expectedSchemaDescription: JsonSchemaDescription,
  existing: JsonPrimitive,
  expected: JsonPrimitive,
  checkedRefs: Record<string, { result: boolean }>,
): boolean {
  if (existing.type === 'string') {
    if (expected.type !== 'string') {
      return false;
    }

    if (existing.format !== expected.format) {
      return false;
    }

    if (!isCompatibleEnum(existing.enum, expected.enum)) {
      return false;
    }
    return true;
  } else if (existing.type === 'boolean') {
    if (expected.type !== 'boolean') {
      return false;
    }

    if (!isCompatibleEnum(existing.enum, expected.enum)) {
      return false;
    }
    return true;
  } else if (existing.type === 'number') {
    if (expected.type !== 'number') {
      return false;
    }

    if (!isCompatibleEnum(existing.enum, expected.enum)) {
      return false;
    }
    return true;
  } else if (existing.type === 'array') {
    if (expected.type !== 'array') {
      return false;
    }
    return isCompatibleSchema(
      existingSchemaDescription,
      expectedSchemaDescription,
      existing.items.type,
      expected.items.type,
      checkedRefs,
    );
  } else if (existing.type === 'object') {
    if (expected.type !== 'object') {
      return false;
    }

    if (expected.required) {
      if (!existing.required) {
        return false;
      }
      for (const expectedRequired of expected.required) {
        if (existing.required.indexOf(expectedRequired) === -1) {
          return false;
        }
      }
    }

    if (expected.additionalProperties) {
      if (existing.additionalProperties) {
        if (
          !isCompatibleSchema(
            existingSchemaDescription,
            expectedSchemaDescription,
            existing.additionalProperties,
            expected.additionalProperties,
            checkedRefs,
          )
        ) {
          return false;
        }
      } else if (existing.properties) {
        for (const prop of Object.values(existing.properties)) {
          if (
            !isCompatibleSchema(
              existingSchemaDescription,
              expectedSchemaDescription,
              prop,
              expected.additionalProperties,
              checkedRefs,
            )
          ) {
            return false;
          }
        }
      }
    }

    if (existing.additionalProperties) {
      if (expected.properties) {
        for (const prop of Object.values(expected.properties)) {
          if (
            !isCompatibleSchema(
              existingSchemaDescription,
              expectedSchemaDescription,
              existing.additionalProperties,
              prop,
              checkedRefs,
            )
          ) {
            return false;
          }
        }
      }
    }

    const existingProperties = getProperties(existing);
    const expectedProperties = getProperties(expected);

    for (const [name, existingProperty] of existingProperties) {
      const expectedProperty = getProperty(expected, name);
      if (!expectedProperty) {
        continue;
      }
      if (
        !isCompatibleSchema(
          existingSchemaDescription,
          expectedSchemaDescription,
          existingProperty,
          expectedProperty,
          checkedRefs,
        )
      ) {
        return false;
      }
    }

    for (const [name, expectedProperty] of expectedProperties) {
      const existingProperty = getProperty(existing, name);
      if (existingProperty) {
        if (
          !isCompatibleSchema(
            existingSchemaDescription,
            expectedSchemaDescription,
            existingProperty,
            expectedProperty,
            checkedRefs,
          )
        ) {
          return false;
        }
      } else if (expected.required) {
        if (expected.required.indexOf(name) >= 0) {
          return false;
        }
      }
    }

    return true;
  } else {
    failNever(existing, 'unknown json primitive type');
  }
}

function getProperties(object: JsonObjectSchema): [string, JsonSchema][] {
  if (object.properties) {
    return Object.entries(object.properties);
  } else {
    return [];
  }
}
function getProperty(
  object: JsonObjectSchema,
  name: string,
): JsonSchema | null {
  if (object.properties && object.properties[name]) {
    return object.properties[name];
  } else if (object.additionalProperties) {
    return object.additionalProperties;
  } else {
    return null;
  }
}

function isCompatibleSchema(
  existingSchemaDescription: JsonSchemaDescription,
  expectedSchemaDescription: JsonSchemaDescription,
  existing: JsonSchema,
  expected: JsonSchema,
  checkedRefs: Record<string, { result: boolean }>,
): boolean {
  const normalizedExisting = normalizeJsonSchema(
    existingSchemaDescription,
    existing,
  );
  const normalizedExpected = normalizeJsonSchema(
    expectedSchemaDescription,
    expected,
  );

  if (normalizedExpected.name && normalizedExpected.name) {
    const key = `${normalizedExisting.name}-${normalizedExpected.name}`;
    const existingCheck = checkedRefs[key];
    if (existingCheck !== undefined) {
      return existingCheck.result;
    } else {
      checkedRefs[key] = { result: true };
    }
  }

  if (isJsonOneOf(normalizedExisting.schema)) {
    for (const existingOneOf of normalizedExisting.schema.oneOf) {
      if (
        !isCompatibleSchema(
          existingSchemaDescription,
          expectedSchemaDescription,
          existingOneOf,
          normalizedExpected.schema,
          checkedRefs,
        )
      ) {
        return false;
      }
    }

    return true;
  } else if (isJsonOneOf(normalizedExpected.schema)) {
    for (const expectedOneOf of normalizedExpected.schema.oneOf) {
      if (
        isCompatibleSchema(
          existingSchemaDescription,
          expectedSchemaDescription,
          normalizedExisting.schema,
          expectedOneOf,
          checkedRefs,
        )
      ) {
        return true;
      }
    }

    return false;
  } else {
    return isCompatiblePrimitiveSchema(
      existingSchemaDescription,
      expectedSchemaDescription,
      normalizedExisting.schema,
      normalizedExpected.schema,
      checkedRefs,
    );
  }
}

export function isCompatibleJsonSchemaDescription(
  existingSchemaDescription: JsonSchemaDescription,
  expectedSchemaDescription: JsonSchemaDescription,
): boolean {
  return isCompatibleSchema(
    existingSchemaDescription,
    expectedSchemaDescription,
    existingSchemaDescription.mainSchema,
    expectedSchemaDescription.mainSchema,
    {},
  );
}

export function isCompatibleTypeDescription(
  existing: TypeDescription,
  expected: TypeDescription,
): boolean {
  const existingSchema = getJsonSchemaDescription(existing);
  const expectedSchema = getJsonSchemaDescription(expected);
  return isCompatibleJsonSchemaDescription(existingSchema, expectedSchema);
}

export function isRequiredType(type: TypeDescription): boolean {
  if (type.type === 'nullable' || type.type === 'optional') {
    return false;
  }

  if (
    type.type === 'union' &&
    type.unionTypes.some((t) => t.type === 'nullable' || t.type === 'optional')
  ) {
    return false;
  }

  return true;
}

export function equalArray(first: string[], second: string[]): boolean {
  if (first.length !== second.length) {
    return false;
  }

  for (let i = 0; i < first.length; i++) {
    if (first[i] !== second[i]) {
      return false;
    }
  }

  return true;
}
