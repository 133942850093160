import { PartitionKey } from './create-partition-storage.js';

export function getPartitionValueObject(
  partitionKeys: PartitionKey[],
): Record<string, string | number | Date | boolean> {
  return partitionKeys.reduce<Record<string, string | number | Date | boolean>>(
    (map, k) => {
      map[k.name] = k.value;
      return map;
    },
    {},
  );
}
