import { MultiStateDefinitions } from './multi-state-definitions.js';

type KeyOf<T> = keyof T & string;

export function createMultiPartState<T extends MultiStateDefinitions>(
  state: T,
): {
  [Prop in KeyOf<T> as `${Prop}-${KeyOf<T[Prop]>}`]: T[Prop][keyof T[Prop]];
} {
  return Object.entries(state).reduce<any>(
    (map, [prefix, stateDefinitions]) => {
      for (const [stateName, stateDefinition] of Object.entries(
        stateDefinitions,
      )) {
        map[`${prefix}-${stateName}`] = stateDefinition;
      }

      return map;
    },
    {},
  );
}
