import { createType } from './create-type.js';
import { OptionalTypeDescription } from './optional-type-description.js';
import { TypeDescription } from './type-description.js';

export const optional = <T extends TypeDescription>(type: T) =>
  createType<OptionalTypeDescription<T['_output']>>({
    description: {
      type: 'optional',
      optionalType: type,
    },
  });
