import {
  IndexOptions,
  StateDeclaration,
  StateIndex,
  StateIndexKeys,
  StateIndexValue,
  StateIndices,
} from './state-declaration.js';
import { object } from '../typing/object.js';
import { ObjectTypeDescription } from '../typing/object-type-description.js';
import { TypeDescription } from '../typing/type-description.js';
import { getJsonSchemaDescription } from '../rpc/server/openapi/json-schema.js';
import { JsonSchemaDescription } from '../rpc/server/openapi/json-schema-description.js';

export function declareState<
  T extends {
    [key: string]: TypeDescription;
  },
  Key extends keyof StateIndexValue<{
    [Prop in keyof T & string]: T[Prop]['_output'];
  }>,
>(
  type: T,
  key: Key[],
): StateDeclaration<
  { [Prop in keyof T & string]: T[Prop]['_output'] },
  Key,
  Record<string, never>
> {
  return stateIndexBuilder(object('state', type), key, {});
}
export function declareStateFromObject<
  Output,
  Key extends keyof StateIndexValue<Output> & string,
>(
  type: ObjectTypeDescription<Output>,
  key: Key[],
): StateDeclaration<Output, Key, {}> {
  return stateIndexBuilder(type, key, {});
}

function stateIndexBuilder<
  T,
  Key extends keyof StateIndexValue<T> & string,
  I extends StateIndices<any>,
>(
  type: ObjectTypeDescription<T>,
  key: Key[],
  indices: I,
): StateDeclaration<T, Key, I> {
  return {
    type,
    schema: getJsonSchemaDescription(type),
    indices,
    key,
    index<K extends string, IK extends StateIndexKeys<T>[]>(
      name: K,
      fields: IK,
      options: IndexOptions,
    ): StateDeclaration<T, Key, I & Record<K, StateIndex<T, IK>>> {
      return stateIndexBuilder<T, Key, I & Record<K, StateIndex<T, IK>>>(
        type,
        key,
        {
          ...indices,
          ...{
            [name]: {
              fields,
              unique: options?.unique ?? false,
            },
          },
        },
      );
    },
  };
}

export function declareEvent<
  T extends {
    [key: string]: TypeDescription;
  },
>(
  props: T,
): {
  type: ObjectTypeDescription<{
    [Prop in keyof T & string]: T[Prop]['_output'];
  }>;
  schema: JsonSchemaDescription;
} {
  const type = object('event', props);
  const schema = getJsonSchemaDescription(type);
  return {
    type,
    schema,
  };
}
