import { string } from '../typing/string.js';
import { array } from '../typing/array.js';
import { AuthDeclaration } from './auth-declaration.js';
import { StreamDeclaration } from './stream-declaration.js';
import { AggregationDeclaration } from './aggregation-declaration.js';
import { TypeOf } from '../typing/type-of.js';
import { optional } from '../typing/optional.js';
import { declareEvent } from '../storage/declare-state.js';

export const RealmDeclaration = declareEvent({
  name: string(),
  tenant: string(),
  auths: array(AuthDeclaration),
  streams: array(StreamDeclaration),
  aggregations: array(AggregationDeclaration),
  token: optional(string()),
});
export type RealmDeclaration = TypeOf<typeof RealmDeclaration.type>;
