import { StateDeclarations } from '@aion/core/storage/state-declaration.js';
import { EventDeclarations } from '@aion/core/event/event-declarations.js';
import {
  AggregateHostRuntime,
  StreamHostRuntime,
} from '@aion/core/runtime/host-runtime.js';
import { RemoteManagement } from './remote-management.js';
import {
  RemoteManagementOptions,
  SessionInterface,
} from './remote-management-options.js';
import { createRemoteStreamInterface } from './create-remote-stream-interface.js';
import { createRemoteAggregationInterface } from './create-remote-aggregation-interface.js';
import { StreamInstanceOptions } from '@aion/core/management/stream-instance-options.js';
import { StreamInterface } from '@aion/core/management/stream-interface.js';
import { createRpcClient } from '../api/create-rpc-client.js';
import { createLazy } from '@aion/core/management/create-lazy.js';
import { AggregateInterface } from '@aion/core/management/aggregate-interface.js';

export function createRemoteManagement<Session extends SessionInterface>(
  opts: RemoteManagementOptions<Session>,
): RemoteManagement<Session> {
  const rpc = createRpcClient({ url: opts.url, env: opts.env });
  const session = opts.session({
    url: opts.url,
    tenant: opts.tenant,
    signal: opts.signal,
    env: opts.env,
  });

  return {
    session,
    // async listStreams(): Promise<StreamInstanceIdentifier[]> {
    //   const api = await apiFactory.getAuthorizedApi();
    //   const result = await api.GET('/api/{tenant}/{realm}/streams', {
    //     params: {
    //       path: {
    //         realm: api.realm,
    //         tenant: opts.tenant,
    //       },
    //     },
    //   });
    //   return handleResponse(result).streams;
    // },
    getStream<
      TState extends StateDeclarations,
      TEvent extends EventDeclarations,
    >(
      stream: StreamHostRuntime<TState, TEvent>,
      options?: Partial<Omit<StreamInstanceOptions, 'auth'>>,
    ): StreamInterface<TState, TEvent> {
      const lazyVersion = createLazy(() =>
        stream.source.version.resolve(opts.env),
      );
      return createRemoteStreamInterface(
        stream.source.state,
        stream.source.events,
        rpc,
        session.sessions,
        lazyVersion,
        {
          tenant: opts.tenant,
          realm: stream.source.realm,
          version: stream.source.version,
          pattern: stream.source.pattern,
          args: options?.args ?? {},
        },
      );
    },
    getAggregation<TState extends StateDeclarations>(
      aggregation: AggregateHostRuntime<TState>,
      options?: Partial<Omit<StreamInstanceOptions, 'auth'>>,
    ): AggregateInterface<TState> {
      const lazyVersion = createLazy(() =>
        aggregation.source.version.resolve(opts.env),
      );
      return createRemoteAggregationInterface(
        aggregation.source.state,
        rpc,
        session.sessions,
        lazyVersion,
        {
          tenant: opts.tenant,
          realm: aggregation.source.realm,
          pattern: aggregation.source.pattern,
          version: aggregation.source.version,
          args: options?.args ?? {},
        },
      );
    },
  };
}
