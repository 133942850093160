import { EventAuth, fallbackAuth } from '../event/event-auth.js';
import { StreamArgs } from './stream-args.js';
import { replacePlaceholders } from './replace-placeholders.js';

export function buildStreamKey(
  pattern: string,
  { auth, args }: { auth?: EventAuth; args: StreamArgs },
): string {
  const authValue = fallbackAuth(auth);

  const placeholderValues = {
    ...Object.entries(args).reduce<Record<string, string>>(
      (map, [key, arg]) => {
        map[`args.${key.toLowerCase()}`] = arg;
        return map;
      },
      {},
    ),
    'auth.sub': authValue.sub,
    'auth.aud': authValue.aud,
    'auth.iss': authValue.iss,
  };

  return replacePlaceholders(pattern, placeholderValues).value;
}
