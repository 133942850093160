import { failNever } from './fail-never.js';

export type HashableValue =
  | string
  | number
  | boolean
  | Date
  | HashableValue[]
  | { [key: string]: HashableValue }
  | { [key: number]: HashableValue };

export function serializeObject(value: HashableValue): string {
  if (typeof value === 'string') {
    return value;
  } else if (typeof value === 'number') {
    return `${value.toString()}`;
  } else if (typeof value === 'boolean') {
    return `${value.toString()}`;
  } else if (value instanceof Date) {
    return `${value.getTime().toString()}`;
  } else if (value instanceof Array) {
    return value
      .map((item) => serializeObject(item))
      .sort(compare)
      .join(',');
  } else if (typeof value === 'object') {
    return Object.entries(value)
      .filter(([, value]) => value !== null && value !== undefined)
      .sort(([firstKey], [secondKey]) => compare(firstKey, secondKey))
      .map(([key, value]) => `{${key}:${serializeObject(value)}}`)
      .join(',');
  } else {
    failNever(value, 'unexpected value');
  }
}

function compare(first: string, second: string): -1 | 0 | 1 {
  if (first < second) {
    return -1;
  } else if (first > second) {
    return 1;
  } else {
    return 0;
  }
}
