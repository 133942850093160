import {
  TransactionClient,
  TransactionStateClient,
} from '../storage-interface.js';
import {
  StateDefinition,
  StateDefinitions,
  StateKeyValues,
  TypeOfStateDeclaration,
} from '../state-declaration.js';
import { StateUpdate } from './state-update.js';
import { SnapshotClient } from '../snapshot-client.js';
import { PatchType, UpsertType } from '../state-client.js';
import { Queue, TopicInstance } from '../../queue/queue.js';
import { stateUpdateTopic } from '../../management/aggregation-attach-topic.js';
import { createLazy } from '../../management/create-lazy.js';
import { Lazy } from '../../management/lazy.js';
import { getStateDataKeys } from '../../runtime/get-state-data-keys.js';
import { getOrFail } from '../../realm/get-or-fail.js';
import { StorageView, TrackedView } from './tracked-state.js';
import { observedClient, ObservedClient } from '../observer/observed-client.js';
import { createStateObserver } from '../observer/create-observer.js';

export function createTrackedView<TState extends StateDefinitions>(
  source: StorageView<TState>,
  storageName: string,
  state: TState,
  queue: Queue,
  signal: AbortSignal,
): TrackedView<TState> {
  const observer = createStateObserver(storageName, state, queue, signal);
  return {
    observe<K extends string & keyof TState>(
      name: K,
    ): ObservedClient<TState[K]> {
      return observedClient(
        source.snapshot(name),
        observer.watch(name),
        state,
        name,
      );
    },
    snapshot<K extends string & keyof TState>(
      name: K,
    ): SnapshotClient<TState[K]> {
      return source.snapshot(name);
    },
  };
}

export function createTrackedStorage<TState extends StateDefinitions>(
  source: TransactionClient<TState>,
  storageName: string,
  state: TState,
  queue: Queue,
): TransactionClient<TState> {
  const lazyTopic = createLazy(() => queue.createTopic(stateUpdateTopic));

  return {
    snapshot<K extends string & keyof TState>(
      name: K,
    ): SnapshotClient<TState[K]> {
      return source.snapshot(name);
    },
    state<K extends string & keyof TState>(
      name: K,
    ): TransactionStateClient<TState[K]> {
      return createTrackedTransactionStateClient(
        storageName,
        name,
        getOrFail(state, name),
        source.state(name),
        lazyTopic,
      );
    },
  };
}

async function emitCallbacks(
  lazyTopic: Lazy<TopicInstance<typeof stateUpdateTopic>>,
  storageName: string,
  update: StateUpdate,
) {
  const topic = await lazyTopic.resolve();
  await topic.publish(
    {
      update,
    },
    {
      attributes: {
        state: update.state,
        storageName,
      },
    },
  );
}

function createTrackedTransactionStateClient<
  TState extends StateDefinition<any, any, any>,
>(
  storageName: string,
  state: string,
  declaration: TState,
  source: TransactionStateClient<TState>,
  delayedTopic: Lazy<TopicInstance<typeof stateUpdateTopic>>,
): TransactionStateClient<TState> {
  return {
    async upsert(val: UpsertType<TState>): Promise<void> {
      await source.upsert(val);
      const { data, keys } = getStateDataKeys(declaration, val);
      await emitCallbacks(delayedTopic, storageName, {
        type: 'upsert',
        state,
        data,
        keys,
      });
    },
    async insert(val: TypeOfStateDeclaration<TState>): Promise<void> {
      await source.insert(val);
      const { data, keys } = getStateDataKeys(declaration, val);
      await emitCallbacks(delayedTopic, storageName, {
        type: 'insert',
        state,
        data,
        keys,
      });
    },
    async delete(id: StateKeyValues<TState>): Promise<void> {
      await source.delete(id);
      const { keys } = getStateDataKeys(declaration, id);
      await emitCallbacks(delayedTopic, storageName, {
        type: 'delete',
        state,
        keys,
      });
    },
    // async clear(): Promise<void> {
    //   await source.clear();
    //   await emitCallbacks(delayedTopic, storageName, {
    //     type: 'clear',
    //     state,
    //   });
    // },
    async patch(val: PatchType<TState>): Promise<void> {
      await source.patch(val);
      const { data, keys } = getStateDataKeys(declaration, val);
      await emitCallbacks(delayedTopic, storageName, {
        type: 'patch',
        state,
        data,
        keys,
      });
    },
  };
}
