interface HandleEntry<T> {
  value: T;
  abortController: AbortController;
  handleCount: number;
}

export interface ReusableMap<T> {
  remove(key: string): void;
  sessions(): string[];
  getOrCreate(
    key: string,
    signal: AbortSignal,
    fn: (signal: AbortSignal) => T,
  ): T;
}

// TODO implement min pool and keep some entries alive
export function createReusableMap<T>(): ReusableMap<T> {
  const map: Record<string, HandleEntry<T>> = {};

  function attachAbort(
    key: string,
    signal: AbortSignal,
    entry: HandleEntry<T>,
  ) {
    signal.addEventListener(
      'abort',
      () => {
        entry.handleCount--;
        if (entry.handleCount === 0) {
          entry.abortController.abort();
          delete map[key];
        }
      },
      { once: true },
    );
  }

  return {
    remove(key: string) {
      const existing = map[key];
      if (existing) {
        existing.abortController.abort();
        delete map[key];
      }
    },
    sessions(): string[] {
      return Object.keys(map);
    },
    getOrCreate: (
      key: string,
      signal: AbortSignal,
      fn: (signal: AbortSignal) => T,
    ) => {
      const existing = map[key];

      if (existing) {
        existing.handleCount++;
        attachAbort(key, signal, existing);
        return existing.value;
      } else {
        const abortController = new AbortController();
        const newValue: HandleEntry<T> = {
          value: fn(abortController.signal),
          abortController,
          handleCount: 1,
        };
        map[key] = newValue;
        attachAbort(key, signal, newValue);
        return newValue.value;
      }
    },
  };
}
