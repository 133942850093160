import { Component, computed, Input, OnInit, signal } from '@angular/core';
import {
  StateDeclaration,
  TypeOfStateDeclaration,
} from '@aion/core/storage/state-declaration.js';
import {
  SnapshotIndexClient,
  SortDirection,
} from '@aion/core/storage/snapshot-index-client.js';
import { SnapshotClient } from '@aion/core/storage/snapshot-client.js';
import { BrowseResult } from '@aion/core/storage/browse-result.js';
import { browse } from '@aion/core/storage/browse.js';
import { timedSignal } from '@aion/core/utils/timedSignal.js';

export interface CellOptions {
  name: string;
  width: string;
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent<T extends StateDeclaration<any, any, any>>
  implements OnInit
{
  @Input()
  client!: SnapshotClient<T> | SnapshotIndexClient<T, any>;

  @Input()
  direction: SortDirection = 'next';

  cells = signal<CellOptions[]>([]);
  columnTemplate = computed(() =>
    this.cells()
      .map((c) => c.width)
      .join(' '),
  );

  data = signal<BrowseResult<TypeOfStateDeclaration<T>>>({
    items: [],
    bookmark: null,
  });

  ngOnInit() {
    browse(
      this.client.range(this.data().bookmark as any, {
        dir: this.direction,
        signal: timedSignal(),
      }), // TODO bookmark correct?
      10,
    ).then((res) => {
      this.data.set({
        bookmark: res.bookmark,
        items: res.items,
      });
    });
  }

  updateCells(cells: CellOptions[]) {
    this.cells.set(cells);
  }
}
