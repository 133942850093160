import { StateDeclarationIndex, StreamState } from '../realm/stream-state.js';
import { StateDefinitions } from '../storage/state-declaration.js';
import { JsonSchemaDescription } from '../rpc/server/openapi/json-schema-description.js';
import { getTypeDescriptionFromJsonSchemaDescription } from '../rpc/server/openapi/json-schema.js';
import { CustomError } from '../custom-error.js';

export function mapStateDeclarations(states: {
  [key: string]: {
    schema: JsonSchemaDescription;
    indices: StateDeclarationIndex;
    key: string[];
  };
}): {
  [key: string]: StreamState;
} {
  return Object.entries(states).reduce<Record<string, StreamState>>(
    (map, [key, value]) => {
      map[key] = {
        indices: value.indices,
        schema: value.schema,
        key: value.key,
      };
      return map;
    },
    {},
  );
}

export function mapStateDefinition(
  state: Record<string, StreamState>,
): StateDefinitions {
  return Object.entries(state).reduce<StateDefinitions>((map, [key, value]) => {
    const type = getTypeDescriptionFromJsonSchemaDescription(value.schema);
    if (type.type !== 'object') {
      throw new CustomError('not a valid object type', null, {
        stateName: key,
      });
    }
    map[key] = {
      key: value.key,
      indices: value.indices,
      schema: value.schema,
      type,
    };
    return map;
  }, {});
}
