import { ContinuousCmd, ReturnCmd } from './return-cmd.js';
import { TypeDescription } from '../../typing/type-description.js';

export interface Cmd<I extends TypeDescription> {
  type: 'cmd';

  input: I;

  returns<O extends TypeDescription>(output: O): ReturnCmd<I, O>;

  continuous<O extends TypeDescription>(output: O): ContinuousCmd<I, O>;
}

export function cmd<I extends TypeDescription>(input: I): Cmd<I> {
  return {
    type: 'cmd',
    input,
    returns<O extends TypeDescription>(output: O): ReturnCmd<I, O> {
      return { type: 'return', input, output };
    },
    continuous<O extends TypeDescription>(output: O): ContinuousCmd<I, O> {
      return { type: 'continuous', input, output };
    },
  };
}
