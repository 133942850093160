import { toArray } from '../storage/to-array.js';
import { PermissionCache } from './permission-cache.js';
import type { ManagementState } from './management-state.js';
import { InstanceIdentifier } from './instance-identifier.js';
import { StorageView } from '../storage/tracker/tracked-state.js';

export function createPermissionCache(
  storage: StorageView<typeof ManagementState>,
  id: InstanceIdentifier,
  signal: AbortSignal,
): PermissionCache {
  return {
    async resolve(
      authId: string,
      eventType: string,
    ): Promise<{ read: boolean; write: boolean }> {
      const currentPermission = await storage.snapshot('permissions').get(
        {
          pattern: id.pattern,
          version: id.version,
          realm: id.realm,
          tenant: id.tenant,
          authId,
          name: id.name,
          type: `event:${eventType}`,
        },
        { signal },
      );

      return {
        read: currentPermission?.read ?? false,
        write: currentPermission?.write ?? false,
      };
    },
    async resolveAll(
      authId: string,
    ): Promise<{ read: boolean; write: boolean; type: string }[]> {
      const result = await toArray(
        storage
          .snapshot('permissions')
          .index('auth')
          .filter('tenant', id.tenant)
          .filter('realm', id.realm)
          .filter('pattern', id.pattern)
          .filter('version', id.version)
          .filter('name', id.name)
          .filter('authId', authId)
          .range(null, { signal }),
      );
      return result.map((i) => ({
        read: i.data.read ?? false,
        write: i.data.write ?? false,
        type: i.data.type,
      }));
    },
  };
}
