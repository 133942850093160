import { StorageProvider } from '../../management/storage-provider.js';
import { StateDefinitions } from '../state-declaration.js';
import { StorageClient } from './tracked-state.js';
import { StorageBuilder, StorageVersion } from './storage-builder.js';
import { defineState } from '../define-state.js';
import { declareState } from '../declare-state.js';
import { string } from '../../typing/string.js';
import { record } from '../../typing/record.js';
import { StreamState } from '../../realm/stream-state.js';
import { createLazy } from '../../management/create-lazy.js';
import {
  mapStateDeclarations,
  mapStateDefinition,
} from '../../management/map-state-declarations.js';
import { createLazyStorageClient } from './lazy-tracked-storage.js';
import { isCompatibleStateDefinitions } from '../memory/is-compatible-state-definitions.js';
import { date } from '../../typing/date.js';
import { generateStateVersion } from '../../realm/generate-version.js';
import { RuntimeEnv } from '../../runtime-env.js';
import { StorageInterface } from '../storage-interface.js';
import { number } from '../../typing/number.js';

const storageState = defineState({
  versions: declareState(
    {
      name: string(),
      checksum: string(),
      version: number(),
      state: record(string(), StreamState),
      createdAt: date(),
    },
    ['name', 'checksum'] as const,
  ).index('name', ['name', 'createdAt'] as const),
});

function getVersionedStorageName(name: string, version: number): string {
  return `${name}_v${version}`;
}

async function getStorageVersion(
  storageManagement: StorageInterface<typeof storageState>,
  name: string,
  checksum: string,
  state: StateDefinitions,
  signal: AbortSignal,
): Promise<number> {
  const existingVersion = await storageManagement
    .snapshot('versions')
    .get({ name, checksum }, { signal });

  if (existingVersion) {
    return existingVersion.version;
  }

  let maxVersion = 0;
  for await (const version of storageManagement
    .snapshot('versions')
    .index('name')
    .filter('name', name)
    .range(null, { dir: 'prev', signal })) {
    const currentState = mapStateDefinition(version.data.state);
    if (isCompatibleStateDefinitions(currentState, state)) {
      await storageManagement.state('versions').insert(
        {
          name,
          checksum,
          version: version.data.version,
          createdAt: new Date(),
          state: mapStateDeclarations(state),
        },
        signal,
      );
    }

    if (version.data.version > maxVersion) {
      maxVersion = version.data.version;
    }
  }

  await storageManagement.state('versions').insert(
    {
      name,
      checksum,
      version: maxVersion + 1,
      createdAt: new Date(),
      state: mapStateDeclarations(state),
    },
    signal,
  );

  return maxVersion + 1;
}

export function createdStorageBuilder(
  provider: StorageProvider,
  env: RuntimeEnv,
  signal: AbortSignal,
): StorageBuilder {
  const lazyStorageManagement = createLazy(() =>
    provider.open(`storage_management`, storageState, signal),
  );

  return {
    open<TState extends StateDefinitions>(
      name: string,
      state: TState,
      signal: AbortSignal,
    ): StorageClient<TState> {
      const lazyStorageClient = createLazy<StorageClient<TState>>(async () => {
        const storageManagement = await lazyStorageManagement.resolve();
        const checksum = await generateStateVersion(state, env);

        const storageVersion = await getStorageVersion(
          storageManagement,
          name,
          checksum,
          state,
          signal,
        );
        const versionedName = getVersionedStorageName(name, storageVersion);

        return provider.open(versionedName, state, signal);
      });

      return createLazyStorageClient(state, lazyStorageClient);
    },
    async *versions(name: string): AsyncGenerator<StorageVersion> {
      const storage = await lazyStorageManagement.resolve();
      for await (const version of storage
        .snapshot('versions')
        .index('name')
        .filter('name', name)
        .range(null, { signal })) {
        yield {
          version: version.data.version,
          name: version.data.name,
          state: mapStateDefinition(version.data.state),
        };
      }
    },
    async destroyAll(name: string, signal: AbortSignal): Promise<void> {
      const storage = await lazyStorageManagement.resolve();
      for await (const version of storage
        .snapshot('versions')
        .index('name')
        .filter('name', name)
        .range(null, { signal })) {
        await provider.destroy(
          getVersionedStorageName(name, version.data.version),
          signal,
        );
      }
    },
    async destroy(
      name: string,
      state: StateDefinitions,
      signal: AbortSignal,
    ): Promise<void> {
      const storageManagement = await lazyStorageManagement.resolve();
      const checksum = await generateStateVersion(state, env);
      const version = await storageManagement
        .snapshot('versions')
        .get({ name, checksum }, { signal });
      if (!version) {
        return;
      }

      return provider.destroy(
        getVersionedStorageName(name, version.version),
        signal,
      );
    },
    async *sessions(): AsyncGenerator<string> {
      for await (const session of provider.sessions()) {
        yield session;
      }
    },
  };
}
