import { StreamEventDeclaration } from '../realm/stream-event-declaration.js';
import { EventDeclaration } from '../event/event-declarations.js';
import { getTypeDescriptionFromJsonSchemaDescription } from '../rpc/server/openapi/json-schema.js';
import { CustomError } from '../custom-error.js';
import { JsonSchemaDescription } from '../rpc/server/openapi/json-schema-description.js';

export function mapEventDeclarations(
  events: Record<string, { schema: JsonSchemaDescription }>,
): {
  [key: string]: StreamEventDeclaration;
} {
  return Object.entries(events).reduce<Record<string, StreamEventDeclaration>>(
    (map, [key, value]) => {
      map[key] = {
        schema: value.schema,
      };
      return map;
    },
    {},
  );
}

export function mapEventDefinition(
  state: Record<string, StreamEventDeclaration>,
): Record<string, EventDeclaration> {
  return Object.entries(state).reduce<Record<string, EventDeclaration>>(
    (map, [key, value]) => {
      const type = getTypeDescriptionFromJsonSchemaDescription(value.schema);
      if (type.type !== 'object') {
        throw new CustomError('not valid object type', null, {});
      }
      map[key] = {
        type,
        schema: value.schema,
      };
      return map;
    },
    {},
  );
}
