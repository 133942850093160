import { TransactionOperation } from '../storage/transaction-operation.js';
import { array } from '../typing/array.js';
import { object } from '../typing/object.js';
import { union } from '../typing/union.js';
import { literal } from '../typing/literal.js';
import { string } from '../typing/string.js';
import { TypeOf } from '../typing/type-of.js';
import { EventAuth } from '../event/event-auth.js';
import { record } from '../typing/record.js';
import { StateValue } from '../storage/state-value.js';
import { optional } from '../typing/optional.js';

export const PermissionLevel = union('PermissionLevel', [
  literal('read'),
  literal('write'),
  literal('readwrite'),
]);

export const PermissionChangeType = union('PermissionChangeType', [
  literal('grant_event'),
  literal('revoke_event'),
]);

export const PermissionChange = object('PermissionChange', {
  type: PermissionChangeType,
  event: string(),
  permission: PermissionLevel,
  auth: EventAuth,
});

export const HostPushSuccessResult = object('HostPushSuccessResult', {
  success: literal(true),
  operation: array(TransactionOperation),
  outputs: array(
    object('HostPushSuccessResultOutput', {
      type: string(),
      data: record(string(), StateValue),
    }),
  ),
  auth: EventAuth,
  event: string(),
  permissions: array(PermissionChange),
});
export type HostPushSuccessResult = TypeOf<typeof HostPushSuccessResult>;

export const HostPushFailResult = object('HostPushFailResult', {
  success: literal(false),
  error: string(),
  stacktrace: optional(string()),
});
export type HostPushFailResult = TypeOf<typeof HostPushFailResult>;

export const RuntimeResult = union('RuntimeResult', [
  HostPushSuccessResult,
  HostPushFailResult,
]);
export type RuntimeResult = TypeOf<typeof RuntimeResult>;
