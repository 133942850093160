import { defineRpc } from '@aion/core/rpc/server/server.js';
import { cmd } from '@aion/core/rpc/client/cmd.js';
import { RealmDeclaration } from '@aion/core/realm/realm-declaration.js';
import { object } from '@aion/core/typing/object.js';
import { string } from '@aion/core/typing/string.js';

export const UploadResult = object('UploadResult', {
  message: string(),
});

export const rpcManagementDefinition = defineRpc({
  upload: cmd(RealmDeclaration.type).returns(UploadResult),
});
