import {
  TransactionClient,
  TransactionStateClient,
} from './storage-interface.js';
import { SnapshotClient } from './snapshot-client.js';
import { StateDefinitions } from './state-declaration.js';

export function createMultiTransactionClient<TState extends StateDefinitions>(
  prefix: string,
  client: TransactionClient<TState>,
): TransactionClient<TState> {
  return {
    state<K extends string>(name: K): TransactionStateClient<TState[K]> {
      return client.state(`${prefix}-${name}` as K);
    },
    snapshot<K extends string & keyof TState>(
      name: K,
    ): SnapshotClient<TState[K]> {
      return client.snapshot(`${prefix}-${name}` as K);
    },
  };
}
