import { StateKeyValues } from '../state-declaration.js';
import { StateValue } from '../state-value.js';

export function compareKeys(
  keys: (StateKeyValues<any> & string)[],
  first: Record<string, StateValue>,
  second: Record<string, StateValue>,
): boolean {
  for (const key of keys) {
    if (first[key] !== second[key]) {
      return false;
    }
  }
  return true;
}
