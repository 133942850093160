import {
  StateDefinition,
  StateDefinitions,
  StateKeyValues,
  TypeOfStateDeclaration,
} from '../state-declaration.js';
import { BrowseResult, RangeResult } from '../browse-result.js';
import {
  observedIndexClient,
  ObservedIndexClient,
} from './observed-index-client.js';
import { ObserveRangeOptions } from './observe-range-options.js';
import { IterateOptions } from './iterate-options.js';
import { ObserveWatcher } from './observer.js';
import { CustomError } from '../../custom-error.js';
import { createLazy } from '../../management/create-lazy.js';
import { browse } from '../browse.js';
import { ObserveSingleOptions } from './observe-single-options.js';
import { SnapshotClient } from '../snapshot-client.js';

export interface ObservedClient<TState extends StateDefinition<any, any, any>> {
  index<K extends keyof TState['indices'] & string>(
    index: K,
  ): ObservedIndexClient<TState, TState['indices'][K]>;

  observe(
    id: StateKeyValues<TState>,
    opts: ObserveSingleOptions,
  ): AsyncGenerator<TypeOfStateDeclaration<TState> | null>;

  iterate(
    bookmark: StateKeyValues<TState> | null,
    opts: IterateOptions,
  ): AsyncGenerator<RangeResult<TypeOfStateDeclaration<TState>>>;

  observeRange(
    bookmark: StateKeyValues<TState> | null,
    opts: ObserveRangeOptions,
  ): AsyncGenerator<BrowseResult<TypeOfStateDeclaration<TState>>>;
}

export function observedClient<
  TState extends StateDefinitions,
  K extends string & keyof TState,
>(
  client: SnapshotClient<TState[K]>,
  observer: ObserveWatcher<TState[K]>,
  state: TState,
  stateName: K,
): ObservedClient<TState[K]> {
  const definition = state[stateName];
  if (!definition) {
    throw new CustomError(`state not found`, null, {
      state: stateName,
    });
  }

  return {
    observeRange(
      bookmark: StateKeyValues<TState[K]> | null,
      opts: ObserveRangeOptions,
    ): AsyncGenerator<BrowseResult<TypeOfStateDeclaration<TState[K]>>> {
      const take = opts?.take ?? 10;
      return observer.watchBatch(
        createLazy(() => browse(client.range(bookmark, opts), take)),
        {
          bookmark,
          orderDirection: opts?.dir ?? 'next',
          take,
          signal: opts.signal,
          keys: definition.key,
          filter: {},
        },
      );
    },
    iterate(
      bookmark: StateKeyValues<TState[K]> | null,
      opts: IterateOptions,
    ): AsyncGenerator<RangeResult<TypeOfStateDeclaration<TState[K]>>> {
      return observer.watchIterator(client.range(bookmark, opts), {
        bookmark,
        orderDirection: opts?.dir ?? 'next',
        signal: opts.signal,
        keys: definition.key,
        filter: {},
      });
    },
    async *observe(
      id: StateKeyValues<TState[K]>,
      opts,
    ): AsyncGenerator<TypeOfStateDeclaration<TState[K]> | null> {
      for await (const r of observer.watchBatch(
        createLazy(() => browse(client.range(id, { signal: opts.signal }), 1)),
        {
          bookmark: id,
          orderDirection: 'next',
          take: 1,
          signal: opts.signal,
          keys: definition.key,
          filter: id,
        },
      )) {
        yield r.items[0]?.data ?? null;
      }
    },
    index<I extends keyof TState[K]['indices'] & string>(
      index: I,
    ): ObservedIndexClient<TState[K], TState[K]['indices'][I]> {
      return observedIndexClient(
        client.index(index),
        observer,
        definition,
        stateName,
        index,
        {},
      );
    },
  };
}
