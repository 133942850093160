import { browserClient } from './browser-client.js';
import { RuntimeEnv } from '../../runtime-env.js';
import { HttpClientResponse } from './http-client-response.js';
import { RpcDefinitions } from '../server/server.js';
import { HttpRpcDefinition } from '../server/http-rpc.js';
import { RpcClientApp } from './app.js';
import { validate } from '../../typing/validate.js';
import { object } from '../../typing/object.js';
import { string } from '../../typing/string.js';
import { record } from '../../typing/record.js';
import { CustomError } from '../../custom-error.js';
import { RpcClientFunction } from './function.js';
import {
  newRootNode,
  parsePath,
  stringifyPath,
} from '../server/server/http-tree.js';

export type RpcBrowserClientErrorHandler = (
  res: HttpClientResponse,
) => Promise<Error | null>;

export interface RpcBrowserClientOptions {
  errorHandler?: RpcBrowserClientErrorHandler;
  url: string;
  env: RuntimeEnv;
}

export class BadRequestError extends Error {
  constructor(
    public headers: { [key: string]: string | string[] },
    public body: any,
  ) {
    super('bad request');
  }
}

async function bodyAsJson(res: HttpClientResponse): Promise<unknown> {
  const body = await res.bodyAsString();
  return JSON.parse(body);
}

export function rpcBrowserClient<T extends RpcDefinitions>(
  definition: T,
  httpDefinition: HttpRpcDefinition<T>,
  options: RpcBrowserClientOptions,
): RpcClientApp<T> {
  const client = browserClient({ baseUrl: options.url, env: options.env });
  const errorHandler: RpcBrowserClientErrorHandler =
    options?.errorHandler ??
    (async (res) => {
      const contentType = res.header('Content-Type');
      if (contentType === 'application/json') {
        const body = await bodyAsJson(res);
        if (res.statusCode === 400) {
          const [err, data] = validate(
            body,
            object('BadRequest', {
              message: string(),
              data: record(string(), string()),
            }),
          );
          if (err) {
            throw err;
          }
          throw new CustomError(data.message, null, data.data);
        }

        return new BadRequestError(res.headers(), body);
      }

      return new BadRequestError(res.headers(), await res.bodyAsString());
    });

  const root = newRootNode();

  return Object.entries(definition).reduce<RpcClientApp<any>>(
    (app, [key, fn]) => {
      const rpcClient: RpcClientFunction<any, any> = {
        async call(arg: any, signal: AbortSignal): Promise<any> {
          const http = httpDefinition[key];
          if (!http) {
            throw new CustomError('http definition not found', null, { key });
          }

          const [err, data] = validate(arg, fn.input);
          if (err) {
            throw err;
          }

          const pathNode = parsePath(root, http.path);
          const parsedPath = stringifyPath(pathNode, data);

          const result = await client
            .body(JSON.stringify(data))
            .post({ path: parsedPath, signal });

          if (fn.type === 'return') {
            if (result.statusCode === 404) {
              return null;
            } else if (result.statusCode === 200) {
              return await bodyAsJson(result);
            }
          } else if (fn.type === 'cmd') {
            if (result.statusCode === 204) {
              return;
            }
          } else if (fn.type === 'continuous') {
            return {
              result: async function* () {
                for await (const data of result.bodyAsEventStream()) {
                  for (const line of data.split('\n')) {
                    if (line.startsWith('data: ')) {
                      yield JSON.parse(line.substring('data: '.length));
                    }
                  }
                }
                console.log('stream ended');
              },
            };
          }

          const error = await errorHandler(result);
          if (error) {
            throw error;
          }
        },
      };
      app[key] = rpcClient;
      return app;
    },
    {},
  );
}
