import { getPlaceholderNames } from './get-placeholder-names.js';
import { CustomError } from '../custom-error.js';

export function replacePlaceholders(
  pattern: string,
  values: Record<string, string>,
): { value: string; usedValues: Record<string, string> } {
  let value = pattern.toLowerCase();
  const usedValues: Record<string, string> = {};
  for (const placeholder of getPlaceholderNames(value)) {
    const placeholderValue = values[placeholder.name];
    if (placeholderValue !== undefined) {
      value = value.replace(placeholder.match, placeholderValue);
      usedValues[placeholder.name] = placeholderValue;
    } else {
      throw new CustomError('missing placeholder value', null, {
        placeholderName: placeholder.name,
      });
    }
  }
  return { value, usedValues };
}
