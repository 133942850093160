import { MemoryIndexTreeNode } from './memory-index-tree-node.js';
import { CustomError } from '../../custom-error.js';
import { StateValue } from '../state-value.js';
import {
  StateDefinition,
  TypeOfStateDeclaration,
} from '../state-declaration.js';
import { failNever } from '../../utils/fail-never.js';

export function compareValues(
  first: string | number,
  second: string | number,
): number {
  if (typeof first === 'string' && typeof second === 'string') {
    return first.localeCompare(second);
  } else if (typeof first === 'number' && typeof second === 'number') {
    return first - second;
  } else {
    throw new CustomError('Invalid index type', null, {
      firstType: typeof first,
      secondType: typeof second,
    });
  }
}

export function getSortValue(value: StateValue): string | number {
  if (typeof value === 'string' || typeof value === 'number') {
    return value;
  } else if (typeof value === 'boolean') {
    return Number(value);
  } else if (value instanceof Date) {
    return new Date(value).getTime();
  } else {
    failNever(value, 'unknown sort value');
  }
}

export function mapResolve<TState extends StateDefinition<any, any, any>>(
  idx: MemoryIndexTreeNode<TState>,
  value: TypeOfStateDeclaration<TState>,
) {
  return idx.map[getSortValue(value)];
}
