import { object } from '../typing/object.js';
import { string } from '../typing/string.js';
import { TypeOf } from '../typing/type-of.js';
import { JsonSchemaDescription } from '../rpc/server/openapi/json-schema-description.js';

export const StreamOutput = object('StreamOutput', {
  name: string(),
  schema: JsonSchemaDescription,
});

export type StreamOutput = TypeOf<typeof StreamOutput>;
