import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TodoStateService } from '../todo-state.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {
  todoState = inject(TodoStateService);

  loginWithGoogle() {
    return this.todoState.management.session.navigateToLogin('google');
  }

  loginWithCustom(username: string, password: string) {
    return this.todoState.management.session.login(
      'custom',
      username,
      password,
    );
  }

  registerWithCustom(username: string, password: string) {
    return this.todoState.management.session.register(
      'custom',
      username,
      password,
    );
  }

  loginWithMicrosoft() {
    return this.todoState.management.session.navigateToLogin('microsoft');
  }
}
