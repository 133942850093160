import { cmd } from '@aion/core/rpc/client/cmd.js';
import { defineRpc } from '@aion/core/rpc/server/server.js';
import { TokenResponse } from './token-response.js';
import { StateBrowse } from './state-browse.js';
import { BrowseItem } from './browse-item.js';
import { BrowseResult } from './browse-result.js';
import { StateGet } from './state-get.js';
import { StateIndexBrowse } from './state-index-browse.js';
import { IndexBrowseResult } from './index-browse-result.js';
import { EventPush } from './event-push.js';
import { GetEvent } from './get-event.js';
import { AuthLoginRequest } from './auth-login-request.js';
import { AuthRegisterRequest } from './auth-register-request.js';
import { AuthorizeRequest } from './authorize-request.js';
import { AuthRefresh } from './auth-refresh.js';
import { record } from '@aion/core/typing/record.js';
import { string } from '@aion/core/typing/string.js';
import { StateValue } from '@aion/core/storage/state-value.js';
import { EventRecord } from '@aion/core/event/event-record.js';

export const loginCmd = cmd(AuthLoginRequest).returns(TokenResponse);
export const registerCmd = cmd(AuthRegisterRequest).returns(TokenResponse);
export const authorizeCmd = cmd(AuthorizeRequest).returns(TokenResponse);
export const refreshCmd = cmd(AuthRefresh).returns(TokenResponse);
export const getEvent = cmd(GetEvent).returns(EventRecord);
export const pushEvent = cmd(EventPush).returns(EventRecord);
export const stateBrowse = cmd(StateBrowse).returns(BrowseResult);
export const stateBrowseObserve = cmd(StateBrowse).continuous(BrowseResult);
export const stateIterate = cmd(StateBrowse).continuous(BrowseItem);
export const stateGet = cmd(StateGet).returns(record(string(), StateValue));
export const stateGetObserve = cmd(StateGet).continuous(
  record(string(), StateValue),
);
export const stateIndexBrowse =
  cmd(StateIndexBrowse).returns(IndexBrowseResult);
export const stateIndexBrowseObserve =
  cmd(StateIndexBrowse).continuous(IndexBrowseResult);
export const stateIndexIterate = cmd(StateIndexBrowse).continuous(BrowseItem);

export const rpcApiDefinition = defineRpc({
  'auth.login': loginCmd,
  'auth.register': registerCmd,
  'auth.authorize': authorizeCmd,
  'auth.refresh': refreshCmd,
  'event.get': getEvent,
  'event.push': pushEvent,

  'state.browse': stateBrowse,
  'state.browse.observe': stateBrowseObserve,
  'state.index.browse': stateIndexBrowse,
  'state.index.browse.observe': stateIndexBrowseObserve,
  'state.get': stateGet,
  'state.get.observe': stateGetObserve,
  'state.iterate': stateIterate,
  'state.index.iterate': stateIndexIterate,
});
