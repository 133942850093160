import { TypeDescription } from './type-description.js';

export function lazyType<T extends TypeDescription>(fn: () => T): T {
  let resolved = false;
  let value: T | undefined;

  return new Proxy(
    {},
    {
      get(target: {}, key: string | symbol): any {
        if (!resolved) {
          value = fn();
          resolved = true;
        }

        if (key === 'toJSON') {
          return () => value;
        }

        return (value as any)[key];
      },
    },
  ) as T;
}
