import { number } from '../typing/number.js';
import { object } from '../typing/object.js';
import { string } from '../typing/string.js';

export const JwtPayload = object('JwtPayload', {
  sub: string(),
  aud: string(),
  iss: string(),
  iat: number(),
});
