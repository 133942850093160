export async function firstOfRange<T>(
  iterator: AsyncGenerator<T>,
): Promise<T | null> {
  const result = await iterator.next();
  if (result.value) {
    return result.value;
  }
  return null;
}

export async function lastOfRange<T>(
  iterator: AsyncGenerator<T>,
): Promise<T | null> {
  let value: T | null = null;
  for await (const item of iterator) {
    value = item;
  }
  return value;
}
