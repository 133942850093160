import { createType } from './create-type.js';
import { ObjectTypeDescription } from './object-type-description.js';
import { TypeDescription } from './type-description.js';

export const object = <
  T extends {
    [key: string]: TypeDescription;
  },
>(
  name: string,
  value: T,
) =>
  createType<
    ObjectTypeDescription<{ [Prop in keyof T & string]: T[Prop]['_output'] }>
  >({
    description: {
      type: 'object',
      name,
      object: Object.entries(value).reduce<T>((map, [key, prop]) => {
        (map as any)[key] = prop;
        return map;
      }, {} as any),
    },
  });
