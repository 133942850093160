import { StateDefinitions } from '../storage/state-declaration.js';
import { InstanceIdentifier } from './instance-identifier.js';
import { InstanceStorage } from './instance-storage.js';
import { createMultiStorage } from '../storage/create-multi-storage.js';
import { StateProcessorState } from './create-state-processor.js';
import { ManagementState } from './management-state.js';
import { DefinitionIdentifier } from '../realm/definition-identifier.js';
import { PartitionBuilder } from './create-partition-storage.js';
import { string } from '../typing/string.js';
import { createTrackedView } from '../storage/tracker/track-storage.js';
import { Queue } from '../queue/queue.js';

export function createDefinitionStorage<TState extends StateDefinitions>(
  identifier: DefinitionIdentifier,
  partitionBuilder: PartitionBuilder,
  state: TState,
  signal: AbortSignal,
) {
  return createMultiStorage(
    partitionBuilder.create([
      {
        name: 'tenant',
        type: string(),
        value: identifier.tenant,
      },
      {
        name: 'realm',
        type: string(),
        value: identifier.realm,
      },
      {
        name: 'pattern',
        type: string(),
        value: identifier.pattern,
      },
      {
        name: 'version',
        type: string(),
        value: identifier.version,
      },
    ]),
    `definitions-$(tenant)-$(realm)-$(pattern)`,
    {
      processor: StateProcessorState,
      state,
      management: ManagementState,
    },
    signal,
  );
}

export function createInstanceStorage<TState extends StateDefinitions>(
  identifier: InstanceIdentifier,
  storageFactory: PartitionBuilder,
  state: TState,
  queue: Queue,
  signal: AbortSignal,
): InstanceStorage<TState> {
  const storage = createDefinitionStorage(
    identifier,
    storageFactory,
    state,
    signal,
  );
  return {
    identifier,
    storage: storage,
    management: storage.storage('management'),
    stateStorage: createTrackedView(
      storage.storage('state'),
      storage.storageName,
      state,
      queue,
      signal,
    ),
    processorStorage: storage.storage('processor'),
  };
}
