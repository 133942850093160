import {
  AuthDefinition,
  JWTAuthDeclarations,
} from '@aion/core/realm/auth-definition.js';
import { CustomError } from '@aion/core/custom-error.js';
import { OAuthTransferStateData } from './o-auth-transfer-state-data.js';
import { JwtSessionData } from '@aion/client/auth/jwt-session-data.js';
import { createLocalSession } from './create-local-session.js';
import { AuthParams } from '@aion/client/auth/auth-params.js';
import { buildJwtSessionData } from '@aion/client/auth/build-jwt-session-data.js';
import { createLazy } from '@aion/core/management/create-lazy.js';
import { createJwtSessionHandler } from '@aion/client/auth/create-jwt-session-handler.js';
import { RealmDefinition } from '@aion/core/realm/realm-definition.js';
import { lazyWrap } from '@aion/core/management/lazy-wrap.js';
import { parseJwt } from '@aion/core/auth/parse-jwt';
import { UnauthenticatedSession } from './unauthenticated-session.js';
import { AuthenticatedSession } from './authenticated-session.js';
import { OAuthOptions } from './o-auth-options.js';
import { OAuthRemoteInterface } from './o-auth-remote-interface.js';
import { createRpcClient } from '@aion/client/api/create-rpc-client.js';
import { Lazy } from '@aion/core/management/lazy.js';
import { getOrCreate } from '@aion/core/utils/get-or-create.js';
import { JwtSession } from '@aion/client/auth/jwt-session.js';
import { JwtPayload } from '@aion/core/auth/jwt-payload.js';

async function loadTransferSession(
  realm: RealmDefinition<any>,
  params: AuthParams,
  options: Required<OAuthOptions>,
): Promise<JwtSessionData | null> {
  const searchParams = new URLSearchParams(
    window.location.search.substring(window.location.search.indexOf('?')),
  );
  const state = searchParams.get('state');
  const code = searchParams.get('code');

  if (!state || !code) {
    return await loadExistingSession(params, options);
  }

  const transferState = await options.transferStorage.load();
  if (!transferState) {
    return await loadExistingSession(params, options);
  }

  await options.transferStorage.clear();

  const api = createRpcClient({ url: params.url, env: params.env });
  const response = await api['auth.authorize'].call(
    {
      realm: transferState.realm,
      tenant: transferState.tenant,
      code,
      redirectUri: transferState.redirectUri,
      provider: transferState.provider,
    },
    params.signal,
  );
  const sessionData = buildJwtSessionData(
    realm,
    params.tenant,
    transferState.provider,
    response.result,
  );
  await options.sessionStorage.save(sessionData);
  return sessionData;
}

async function loadExistingSession(
  params: AuthParams,
  options: Required<OAuthOptions>,
): Promise<JwtSessionData | null> {
  return await options.sessionStorage.load();
}

export function createOAuthStorage<TAuth extends AuthDefinition>(
  realm: RealmDefinition<TAuth>,
  opts: OAuthOptions,
): (params: AuthParams) => OAuthRemoteInterface<TAuth> {
  const sessionStorage =
    opts?.sessionStorage ?? createLocalSession('auth_session', JwtSessionData);

  const transferStorage =
    opts?.transferStorage ??
    createLocalSession('auth_transfer', OAuthTransferStateData);

  const sessionDataMaps: Record<string, Lazy<JwtSessionData | null>> = {};
  const sessionMaps: Record<string, Lazy<JwtSession | null>> = {};

  return (params) => {
    const lazySession = getOrCreate(
      sessionDataMaps,
      `${params.tenant}:${params.url}`,
      () =>
        createLazy(() =>
          loadTransferSession(realm, params, {
            sessionStorage,
            transferStorage,
          }),
        ),
    );
    const api = createRpcClient({ url: params.url, env: params.env });

    const lazySessionHandler = getOrCreate(
      sessionMaps,
      `${params.tenant}:${params.url}`,
      () =>
        lazyWrap(lazySession, (session) => {
          if (session) {
            return createJwtSessionHandler(api, {
              data: session,
              abort: new AbortController(),
            });
          } else {
            return null;
          }
        }),
    );

    return {
      sessions: {
        async resolveToken(): Promise<string | undefined> {
          const session = await lazySessionHandler.resolve();
          if (!session) {
            return undefined;
          }
          return session.resolveToken();
        },
      },
      async on(type: 'error', cb: (err: unknown) => void): Promise<void> {
        const sessionHandler = await lazySessionHandler.resolve();
        if (sessionHandler) {
          return sessionHandler.on(type, cb);
        }
      },
      async login(
        provider: keyof JWTAuthDeclarations<TAuth> & string,
        username: string,
        password: string,
      ): Promise<void> {
        const config = realm.auth[provider];

        if (!config) {
          throw new CustomError('unknown provider', null, {
            provider,
          });
        }

        const api = createRpcClient({ url: params.url, env: params.env });
        const response = await api['auth.login'].call(
          {
            tenant: params.tenant,
            realm: realm.name,
            provider,
            username,
            password,
          },
          params.signal,
        );
        const sessionData = buildJwtSessionData(
          realm,
          params.tenant,
          provider,
          response.result,
        );
        await sessionStorage.save(sessionData);
      },
      async register(
        provider: keyof JWTAuthDeclarations<TAuth> & string,
        username: string,
        password: string,
      ): Promise<void> {
        const config = realm.auth[provider];

        if (!config) {
          throw new CustomError('unknown provider', null, {
            provider,
          });
        }

        const api = createRpcClient({ url: params.url, env: params.env });
        const response = await api['auth.register'].call(
          {
            tenant: params.tenant,
            realm: realm.name,
            provider,
            username,
            password,
          },
          params.signal,
        );
        const sessionData = buildJwtSessionData(
          realm,
          params.tenant,
          provider,
          response.result,
        );
        await sessionStorage.save(sessionData);
      },
      navigateToLogin: async (provider) => {
        const state = Math.random().toString(36);
        const config = realm.auth[provider];

        if (!config) {
          throw new CustomError('unknown provider', null, {
            provider,
          });
        }

        if (config.type !== 'openid') {
          throw new CustomError('expected openid provider', null, {
            provider,
          });
        }

        const qs = {
          ...config.authorize.queryParams,
          state,
          redirect_uri: window.location.origin + window.location.pathname,
        };

        await transferStorage.save({
          redirectUri: qs.redirect_uri,
          provider: provider,
          tenant: params.tenant,
          realm: realm.name,
        });

        window.location.href = `${config.authorize.url}?${Object.entries(qs)
          .map(([key, value]) => `${key}=${String(value)}`)
          .join('&')}`;
      },
      logout: async () => {
        await sessionStorage.clear();
        await transferStorage.clear();
        await lazySession.close(() => {});
        await lazySessionHandler.close(() => {});
      },
      async resolveAuth(): Promise<
        UnauthenticatedSession | AuthenticatedSession
      > {
        const session = await lazySession.resolve();
        if (session) {
          const payload = parseJwt(session.accessToken, 1, JwtPayload);
          return {
            type: 'authenticated',
            auth: {
              iss: payload.iss,
              aud: payload.aud,
              sub: payload.sub,
            },
            payload,
          };
        } else {
          return { type: 'unauthenticated' };
        }
      },
    };
  };
}
