import { IDBPTransaction } from 'idb';
import { StateDefinition } from '@aion/core/storage/state-declaration.js';
import { assert } from '@aion/core/typing/assert.js';
import { getStateKeys } from '@aion/core/runtime/get-state-data-keys.js';
import { PatchType } from '@aion/core/storage/state-client.js';
import { getKeyRange } from './get-key-range.js';
import { CustomError } from '@aion/core/custom-error.js';

export async function patch<TState extends StateDefinition<any, any, any>>(
  trx: IDBPTransaction<unknown, string[], 'readwrite'>,
  storeName: string,
  type: TState,
  val: PatchType<TState>,
): Promise<void> {
  const keys = getStateKeys(type, val);
  if (!val) {
    throw new Error('patch can not be empty');
  }
  const store = trx.objectStore(storeName);
  const cursor = await store.openCursor(getKeyRange(type, keys));
  if (cursor && cursor.value) {
    const newVal = { ...val, ...cursor.value.data };
    assert(newVal, type.type);
    await cursor.update(newVal);
  } else {
    throw new CustomError('does not exist', null, { keys });
  }
}
