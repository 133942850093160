import { object } from '../../../typing/object.js';
import { JsonSchemaType } from './json-schema-type.js';
import { string } from '../../../typing/string.js';
import { TypeOf } from '../../../typing/type-of.js';
import { record } from '../../../typing/record.js';

export const JsonSchemaDescription = object('JsonSchemaDescription', {
  schemas: record(string(), JsonSchemaType),
  mainSchema: JsonSchemaType,
});
export type JsonSchemaDescription = TypeOf<typeof JsonSchemaDescription>;
