import { validate } from './validate.js';
import { TypeDescription } from './type-description.js';

export function assert(
  val: unknown,
  type: TypeDescription,
): asserts val is TypeDescription['_output'] {
  const [err] = validate(val, type);
  if (err) {
    throw err;
  }
}
